import React, { useState, useEffect, useRef } from 'react';
import './BookCardSearch.css'; // Import your book card styles
import failedbooks from '../../assets/images/book.png';   
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { apiInstance } from '../../utils/axiosInstance'; 
import { fetchAuthSession } from 'aws-amplify/auth';

const BookCardSearch = React.memo(({ book, onStatusUpdate }) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [status, setStatus] = useState(book.user_status === 'NONE' ? '' : (book.user_status || ''));

    const userGeoInfo = useSelector(state => state.user.userGeoInfo);
    const userCountryCode = userGeoInfo?.country_code || "US";
    

    useEffect(() => {
        dispatch(checkAuthenticationStatus());
    }, [dispatch]); // Runs only once on mount
  

    useEffect(() => {
      if (book.available_at_BN && book.example_affiliate_bn) {
        console.log(`✅ Found valid BN link:`, {
            book: book
        });
    } else if (book.available_at_BN && !book.example_affiliate_bn) {
        console.warn(`⚠️ Missing BN link:`, {
            book: book
        });
    }
    
  }, [book]);

  console.log("Rendering BookCardSearch for:", book.title);

  

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
          }
        };
      
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }, [dropdownOpen]);

      const onBuyClick = () => {
        let affiliateUrl = book.affiliate_url; // Preserve existing behavior for random books
      
        if (!affiliateUrl) {
          if (userCountryCode === 'GB' || userCountryCode === 'IE') {
            affiliateUrl = book.example_affiliate_waterstones || book.example_affiliate_bn || book.example_affiliate_bam;
          } else {
            affiliateUrl = book.example_affiliate_bn || book.example_affiliate_bam || book.example_affiliate_waterstones;
          }
      
          // Final fallback to example_link
          if (!affiliateUrl) {
            affiliateUrl = book.example_link;
          }
        }
      
        if (affiliateUrl) {
          window.open(affiliateUrl, '_blank');
        } else {
          console.error('No affiliate link available for this book:', book);
        }
      };
      

      useEffect(() => {
    if (
        (book.available_at_BN && !book.example_affiliate_bn) ||
        (book.available_at_BAM && !book.example_affiliate_bam) ||
        (book.available_at_Waterstones && !book.example_affiliate_waterstones)
    ) {
        console.log('⚠️ Missing affiliate link:', {
            title: book.title,
            author: book.author,
            available_at_BN: book.available_at_BN,
            available_at_BAM: book.available_at_BAM,
            available_at_Waterstones: book.available_at_Waterstones,
            example_affiliate_bn: book.example_affiliate_bn || "❌ MISSING",
            example_affiliate_bam: book.example_affiliate_bam || "❌ MISSING",
            example_affiliate_waterstones: book.example_affiliate_waterstones || "❌ MISSING"
        });
    }
}, [book]);




  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const capitalizeAuthorName = (name) => {
    if (!name) return ""; // Return empty string instead of "Unknown Author"
    return name.split(' ').map(capitalizeFirstLetter).join(' ');
  };
  
  const capitalizeFirstWordOfTitle = (title) => {
    const words = title.split(' ');
    words[0] = capitalizeFirstLetter(words[0]);
    return words.join(' ');
  };

  const handleStatusChange = async (newStatus) => {
    const updatedStatus = newStatus === "REMOVE" ? "NONE" : newStatus;
    setDropdownOpen(false);
  
    try {
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();
  
      const payload = {
        user_id: userId,
        author: book.author,
        title: book.title,
        external_product_id: book.external_product_id,
        status: updatedStatus,
        retailer_id: book.retailer_id,
        retailer_name: book.retailer_name,
        image_link: book.example_image,
        affiliate_id: book.affiliate_id,
        product_link_url: book.product_link_url,
        affiliate_url: book.affiliate_url
      };

      setStatus(newStatus === "REMOVE" ? "" : newStatus);
  
      await apiInstance.post('/users/book/change-status', payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (onStatusUpdate) {
        onStatusUpdate({
          ...book,
          status: updatedStatus
        });
      }
  
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const RetryableImage = ({ src, alt, className, maxRetries = 10, interval = 1000 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(failedbooks); // Initially set to fallback image
  
    useEffect(() => {
      const img = new Image();
      img.onload = () => {

        setImageSrc(src); // Only set to the original source on successful load
      };
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            console.log(`Retrying image load: Attempt ${retryCount + 1}`);
            setRetryCount(retryCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`; // Attempt to reload with retry query
          }, interval);
        } else {
          setImageSrc(failedbooks); // Keep the fallback image if max retries are exceeded
        }
      };
      img.src = `${src}?retry=${retryCount}`; // Start loading with retry count to bypass cache
      // Set the source to the fallback image every time the component attempts to load the original image
      setImageSrc(failedbooks);
  
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval]);
  
    // Always render the img element with the current source
    return <img src={imageSrc} alt={alt} className={className} />;
  };

  const capitalizedTitle = capitalizeFirstWordOfTitle(book.title);
  const capitalizedAuthor = capitalizeAuthorName(book.author);



  return (
    <>
      <div className="book-cards">
        <RetryableImage
          className="bookie-image"
          src={book.example_image}
          alt={book.title}
          maxRetries={0}
          interval={1000}
        />
        <div className="book-info">
          <h3>{capitalizedTitle}</h3>
          <p>By {capitalizedAuthor}</p>
        </div>
      </div>

      <div className="book-actions">
        {!isAuthenticated ? (
          <button
            className="buttoninbox boldButton fullWidthItem"
            onClick={onBuyClick}
          >
            Buy the book
          </button>
        ) : (
          <div className="actionRowFullWidth">
            <button 
              className="buttoninbox boldButton halfWidthItem" 
              onClick={onBuyClick}
            >
              Buy the book
            </button>

            <div className="bookStatusDropdownWrapper halfWidthItem" ref={dropdownRef}>
              <button
                className={`buttoninbox boldButton bookStatusDropdownTrigger ${
                  status ? "" : "inactive"
                }`}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {status === ""
                  ? "Add to shelf"
                  : status === "READ"
                    ? "Have read"
                    : status === "OWN"
                      ? "Own"
                      : status === "WANT"
                        ? "Interested in"
                        : status === "REMOVE"
                          ? "Remove from current shelf"
                          : status
                }
                <span className="bookStatusDropdownTriangle">&#9662;</span>
              </button>

              {dropdownOpen && (
                <div className="bookStatusDropdown">
                  <ul>
                    <li
                      onClick={() => handleStatusChange("READ")}
                      className={status === "READ" ? "selected" : ""}
                    >
                      Have read
                    </li>
                    <li
                      onClick={() => handleStatusChange("OWN")}
                      className={status === "OWN" ? "selected" : ""}
                    >
                      Own (but unread)
                    </li>
                    <li
                      onClick={() => handleStatusChange("WANT")}
                      className={status === "WANT" ? "selected" : ""}
                    >
                      Want to read
                    </li>
                    {status && (
                      <li onClick={() => handleStatusChange("REMOVE")}>Remove from shelf</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default BookCardSearch;