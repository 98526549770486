import React, { useState, useEffect, useCallback} from 'react';
import { apiInstance } from '../../utils/axiosInstance'; // Adjust the import path as necessary
import { Helmet } from 'react-helmet';
import Header from '../../components/HeaderTheFeed/HeaderTheFeed';
import './TheFeed.css';
import {useNavigate, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { setShouldApplyFilters, setFeedData, setSelectedFeedType, resetFilters } from '../../store/theFeedSlice';
import { setPieceData } from '../../store/pieceViewFromFeedSlice';
import usePreviousRoute from '../../hooks/usePreviousRoute';
import feedingIcon from '../../assets/images/feeding.png'; 
import heart from '../../assets/images/heart.svg';
import reads from '../../assets/images/read-book-icon.svg';
import BooksInPiece from '../../components/BooksInPiece/BooksInPiece'
import { showGenericModal } from '../../store/modalSlice';
// import Footer from '../../components/Footer/Footer';

 
const TheFeed = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const geoInfo = useSelector(state => state.user.userGeoInfo); 
  const [pieces, setPieces] = useState([]);
  const userId = useSelector(state => state.user.userId);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [supportsWebp, setSupportsWebp] = useState(false);
  const shouldApplyFilters = useSelector(state => state.theFeed.shouldApplyFilters);
  const currentFilters = useSelector(state => state.theFeed);
  const store =  useSelector(state => state.pieceViewFromFeed);
  const [isLoading, setIsLoading] = useState(true);
  const feedData = useSelector(state => state.pieceViewFromFeed.pieceData);
  const feedViewHash = useSelector(state => state.pieceViewFromFeed.viewHash);
  const [showLoading, setShowLoading] = useState(true);
  console.log('🔥 Geo Info from Redux:', geoInfo);
  const isFiltering = useSelector(state => state.theFeed.isFiltering);
  const piecesShorties = useSelector(state => state.theFeed.piecesShorties);
  const piecesLongform = useSelector(state => state.theFeed.piecesLongform);
  const hasFetchedFeed = useSelector(state => state.theFeed.hasFetchedFeed);
  const feedType = useSelector(state => state.theFeed.selectedFeedType);



  const MINIMUM_LOADING_TIME =400;

  const fetchFeed = useCallback((filters = null) => {
    const startTime = Date.now();
    setIsLoading(true);
    setShowLoading(true);
    
    let url = '/pieces/thefeed/fetch/v2';
    let params = userId ? { consumer_id: userId } : {}; // Ensures we always start fresh
    console.log('🔥 Initial params:', { ...params });


    console.log('Fetching feed with filters:', filters);
  
    if (filters) {
      if (filters.genre && filters.genre.length > 0) {
        params.genres = filters.genre.join(',');
      }
      if (filters.style && filters.style.length > 0) {
        params.styles = filters.style.join(',');
      }
      if (filters.length && filters.length.length > 0) {
        params.lengths = filters.length.join(',');
      }
      if (filters.decade && filters.decade.length > 0) {
        params.decades = filters.decade.join(',');
      }
      // Add locationGrewUp and locationLiveNow if needed
      if (filters.locationGrewUp && (filters.locationGrewUp.country || filters.locationGrewUp.region)) {
        params.locationGrewUpCountry = filters.locationGrewUp.country;
        params.locationGrewUpRegion = filters.locationGrewUp.region;
      }
      if (filters.locationLiveNow && (filters.locationLiveNow.country || filters.locationLiveNow.region)) {
        params.locationLiveNowCountry = filters.locationLiveNow.country;
        params.locationLiveNowRegion = filters.locationLiveNow.region;
      }
    }

    console.log('🔥 Final params before request:', { ...params });
  
    apiInstance.get(url, { params })
    .then(response => {
      setPieces(response.data.feed_data);
      const endTime = Date.now();
      const loadTime = endTime - startTime;
      
      if (loadTime < MINIMUM_LOADING_TIME) {
        setTimeout(() => {
          setIsLoading(false);
          setTimeout(() => setShowLoading(false), 500); // Fade out effect
        }, MINIMUM_LOADING_TIME - loadTime);
      } else {
        setIsLoading(false);
        setTimeout(() => setShowLoading(false), 500); // Fade out effect
      }
    })
    .catch(error => {
      console.error('Error fetching the feed:', error);
      setIsLoading(false);
      setTimeout(() => setShowLoading(false), 500); // Fade out effect
    });
}, [userId]);

const fetchFeeds = useCallback((filters = null) => {
  const startTime = Date.now();
  setIsLoading(true);
  setShowLoading(true);

  let url = '/pieces/thefeed/fetch/v2';
  let params = userId ? { consumer_id: userId } : {}; // Ensures fresh start

  console.log('🔥 Initial params:', { ...params });

  // Apply filters if they exist
  if (filters) {
    if (filters.genre && filters.genre.length > 0) {
      params.genres = filters.genre.join(',');
    }
    if (filters.style && filters.style.length > 0) {
      params.styles = filters.style.join(',');
    }
    if (filters.length && filters.length.length > 0) {
      params.lengths = filters.length.join(',');
    }
    if (filters.decade && filters.decade.length > 0) {
      params.decades = filters.decade.join(',');
    }
    if (filters.locationGrewUp && (filters.locationGrewUp.country || filters.locationGrewUp.region)) {
      params.locationGrewUpCountry = filters.locationGrewUp.country;
      params.locationGrewUpRegion = filters.locationGrewUp.region;
    }
    if (filters.locationLiveNow && (filters.locationLiveNow.country || filters.locationLiveNow.region)) {
      params.locationLiveNowCountry = filters.locationLiveNow.country;
      params.locationLiveNowRegion = filters.locationLiveNow.region;
    }
  }

  console.log('🔥 Final params before request:', { ...params });
  

  // Make both requests in parallel
  Promise.all([
    apiInstance.get(url, { params: { ...params, feed_type: 'shorties' } }),
    apiInstance.get(url, { params: { ...params, feed_type: 'longform' } })
  ])
    .then(([shortiesRes, longformRes]) => {

      const shorties = shortiesRes.data.feed_data || [];
      const longform = longformRes.data.feed_data || [];

      dispatch(setFeedData({
        shorties: shortiesRes.data.feed_data,
        longform: longformRes.data.feed_data,
      }));

      if (shorties.length > 0 && longform.length === 0) {
        dispatch(setSelectedFeedType('shorties'));
      } else if (longform.length > 0 && shorties.length === 0) {
        dispatch(setSelectedFeedType('longform'));
      }

      console.log(longform);

      const endTime = Date.now();
      const loadTime = endTime - startTime;
      
      if (loadTime < MINIMUM_LOADING_TIME) {
        setTimeout(() => {
          setIsLoading(false);
          setTimeout(() => setShowLoading(false), 500); // Fade out effect
        }, MINIMUM_LOADING_TIME - loadTime);
      } else {
        setIsLoading(false);
        setTimeout(() => setShowLoading(false), 500);
      }
    })
    .catch(error => {
      console.error('Error fetching the feed:', error);
      setIsLoading(false);
      setTimeout(() => setShowLoading(false), 500);
    });
    
}, [userId]);

useEffect(() => {
  let isMounted = true; // Flag to track if component is still mounted

  const initializeFeed = async () => {
    if (!isAuthenticated) {
      await dispatch(checkAuthenticationStatus());
    }

    // If feed data was never fetched (user left before it completed), refetch
    if (!hasFetchedFeed) {
      console.log('user id', userId);
      await dispatch(resetFilters());
      fetchFeed();
      fetchFeeds();
      console.log('user id', userId);
    }
  };

  initializeFeed();

  return () => {
    isMounted = false; // Mark component as unmounted
    setIsLoading(false); // Ensure loading resets if unmounted mid-fetch
  };
}, [isAuthenticated, dispatch, fetchFeed, fetchFeeds]);


  const getLengthCategory = (length) => {
    if (length <= 250) return 'Bite Sized';
    if (length <= 1500) return 'Flash';
    if (length <= 5000) return 'Shorter Story';
    return 'Short Story';
  };

  const handleFilterPress = () => {
    dispatch(showGenericModal({
      modalType: 'THE_FEED_OPTIONS',
      modalProps: {}
    }));
  };

  const prevRoute = usePreviousRoute();

  const getEmptyResultsMessage = () => {
    let message = "Oh no! We haven't got any content that match your filters - perhaps you know a writer ";
    let isFirstCondition = true;
  
    const addFilter = (condition, text) => {
      if (condition) {
        if (!isFirstCondition) {
          message += "and ";
        }
        message += text + " ";
        isFirstCondition = false;
      }
    };
  
    // Decade (moved to the top)
    const decadeCategories = [
      { label: "20s (1920s)", value: "20s" },
      { label: "30s (1930s)", value: "30s" },
      { label: "40s (1940s)", value: "40s" },
      { label: "50s (1950s)", value: "50s" },
      { label: "60s (1960s)", value: "60s" },
      { label: "70s (1970s)", value: "70s" },
      { label: "80s (1980s)", value: "80s" },
      { label: "90s (1990s)", value: "90s" },
      { label: "00s (2000s)", value: "00s" },
      { label: "10s (2010s)", value: "10s" },
    ];
    if (currentFilters.decade && currentFilters.decade.length > 0) {
      const orderedDecades = decadeCategories
        .filter(decade => currentFilters.decade.includes(decade.value))
        .map(decade => decade.value);
      
      if (orderedDecades.length > 0) {
        let decadeText;
        if (orderedDecades.length === 1) {
          decadeText = `the ${orderedDecades[0]}`;
        } else if (orderedDecades.length === 2) {
          decadeText = `the ${orderedDecades[0]} or ${orderedDecades[1]}`;
        } else {
          const lastDecade = orderedDecades.pop();
          decadeText = `the ${orderedDecades.join(', ')}, or ${lastDecade}`;
        }
        
        addFilter(true, `who grew up in ${decadeText}`);
      }
    }
  
    // Genre
    if (currentFilters.genre && currentFilters.genre.length > 0) {
      addFilter(true, `who specializes in ${currentFilters.genre.join(' or ')}`);
    }
  
    // Style
    if (currentFilters.style && currentFilters.style.length > 0) {
      const styles = currentFilters.style.map(style => {
        if (style === "Review") return "reviews";
        return style.toLowerCase() + (style.endsWith('y') ? '' : 's');
      });
      addFilter(true, `who writes ${styles.join(' or ')}`);
    }
  
    // Location Live Now
    if (currentFilters.locationLiveNow && (currentFilters.locationLiveNow.country || currentFilters.locationLiveNow.region)) {
      const location = currentFilters.locationLiveNow.region ? currentFilters.locationLiveNow.region.label : 
                     (currentFilters.locationLiveNow.country ? currentFilters.locationLiveNow.country.label : null);
      addFilter(location, `who lives in ${location}`);
    }
  
    // Location Grew Up
    if (currentFilters.locationGrewUp && (currentFilters.locationGrewUp.country || currentFilters.locationGrewUp.region)) {
      const location = currentFilters.locationGrewUp.region ? currentFilters.locationGrewUp.region.label : 
                       (currentFilters.locationGrewUp.country ? currentFilters.locationGrewUp.country.label : null);
      addFilter(location, `who grew up in ${location}`);
    }
  
    message += "that you might want to recommend to ShorterStories?";
  
    // If no filters are active, return a default message
    if (message === "Oh no! We haven't got any content that match your filters - perhaps you know a writer that you might want to recommend to ShorterStories?") {
      return "Oh no! We haven't got any content that match your filters - perhaps you know a writer you might want to recommend to ShorterStories?";
    }
  
    return message;
  };

  useEffect(() => {
    const initializeFeed = async () => {
      if (!isAuthenticated) {
        await dispatch(checkAuthenticationStatus());
      }

      if (!hasFetchedFeed) {
        console.log('user id', userId);
        await dispatch(resetFilters());
        fetchFeed();
        fetchFeeds();
        console.log('user id', userId);
      }else {
        // 🔥 Defensive check: ensure loading resets if we aren't fetching
        setIsLoading(false);
        setShowLoading(false);
      }

      
    };
  
    initializeFeed();
  }, [isAuthenticated, dispatch, fetchFeed, fetchFeeds]);




  useEffect(() => {
    if (shouldApplyFilters) {
      fetchFeeds(currentFilters);  // Use fetchFeeds instead of fetchFeed
      console.log('Applying filters:', currentFilters);
      dispatch(setShouldApplyFilters(false));
    }
  }, [shouldApplyFilters, currentFilters, fetchFeeds, dispatch]);

  const goToPiecePage = async (item) => {
    const generateHash = (length) => {
      return crypto.getRandomValues(new Uint8Array(length))
        .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
        .slice(0, length);
    };

    const pieceViewHash = generateHash(12);

    const pieceViewData = {
      piece_view_hash: pieceViewHash,
      piece_id: item.piece.piece_id,
      piece_hash: item.piece.piece_hash,
      referrer_page: prevRoute,
      consuming_user: userId,
      referrer_external: document.referrer,
      piece_writer_name: item.author_info.name,
      profile_hash: item.author_info.profile_hash,
      publishing_user: item.piece.publishing_user,
    };

    apiInstance.post('/clicks/pieceview/create', pieceViewData)
      .then(response => console.log('Piece view logged successfully feed', response))
      .catch(error => console.error('Error logging piece view:', error));

    await dispatch(setPieceData({
      pieceData: item, 
      viewHash: pieceViewHash
    }));

    // Only do this for shorties
    if (feedType === 'shorties') {
      const currentIndex = piecesShorties.findIndex(p => p.piece.piece_hash === item.piece.piece_hash);
      const remainingQueue = currentIndex !== -1 ? piecesShorties.slice(currentIndex + 1) : [];

      navigate(`/piece/${item.piece.piece_hash}`, {
        state: {
          prevRoute: prevRoute,
          shortieQueue: remainingQueue, // Send remaining shorties as a queue
        }
      });
    } else {
      navigate(`/piece/${item.piece.piece_hash}`, {
        state: { prevRoute: prevRoute }
      });
    }
};


  const goToSeriesPage = async (item) => {
    window.scrollTo(0, 0);
    navigate(`/serial/${item.piece.serial_first_profile_hash}`);
  };
  

  const handleAuthorClick = (item) => {
    window.scrollTo(0, 0);
    navigate(`/writer/${item.author_info.profile_hash}`, { state: { authorInfo: item.author_info } });
  };


  const numberToWord = (num) => {
    const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    return num >= 1 && num <= 9 ? words[num - 1] : num.toString();
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    if (img.naturalWidth * 1 < img.naturalHeight) {
      img.style.width = '100%';
      img.style.height = `${img.offsetWidth * 1}px`;
      img.style.objectFit = 'cover';
    } else {
      img.style.width = '100%';
      img.style.height = 'auto';
    }
  };

  function supportersWebp() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }

  useEffect(() => {
    setSupportsWebp(supportersWebp());
  }, []);

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleInfo = (route) => {
    console.log(`Navigating to ${route}`);
    window.scrollTo(0, 0);
    navigate(route);
  };

  const activePieces = feedType === 'shorties' ? piecesShorties : piecesLongform;




  return (
    <div className="theFeedPage">
      <Helmet>
        <title>The Feed | ShorterStories</title>
        <meta name="description" content="Discover a curated feed of short stories, flash fiction, and bite-sized literature from talented writers on ShorterStories. Explore diverse genres and styles in our unique writing platform." />
        <meta name="keywords" content="short stories, flash fiction, bite-sized literature, writer platform, ShorterStories, The Feed" />
        <meta property="og:title" content="The Feed | ShorterStories" />
        <meta property="og:description" content="Explore a curated feed of short stories and flash fiction from diverse writers on ShorterStories. Discover new voices and exciting narratives in our unique writing platform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.shorterstories.xyz/thefeed" />
        <link rel="canonical" href="https://www.shorterstories.xyz/thefeed" />
      </Helmet>
  
      <Header />
  
      <div className="theFeed-contentContainer">
        <div className="feed-header-container">
          <div className="feed-header-box">
            {isAuthenticated ? (
              <>
                <p className="feed-header-sub-title">Welcome to ShorterStories, here's a quick guide:</p>
                <p className="feed-header-text-ies">
                  You navigate using the footer. In the feed section, you'll find the latest quick reads and longform from writers across genres — filterable to refine to your taste. In the find section, you can search for books and writers. And in your library, you keep track of all your favorite reads.
                </p>
              </>
            ) : (
              <>
                <p className="feed-header-sub-title">Howdy partner</p>
                <p className="feed-header-text">
                  ShorterStories is way better with an account. Sign up to personalize your feed, build a library, save your favorites and join the best literary community this side of the international date line.
                </p>
                <button className="feed-signup-button" onClick={() => handleInfo("/login")}>Create your account / log in</button>
              </>
            )}
          </div>
        </div>
  
        <div className="feed-mode-toggle-container">
          {(piecesShorties.length > 0 || piecesLongform.length > 0) && (
            <>
              <button
                className={`pill-button ${feedType === 'shorties' ? 'active' : ''} ${piecesShorties.length === 0 ? 'disabled' : ''}`}
                onClick={() => piecesShorties.length > 0 && dispatch(setSelectedFeedType('shorties'))}
              >
                Shorties
              </button>
              <button
                className={`pill-button ${feedType === 'longform' ? 'active' : ''} ${piecesLongform.length === 0 ? 'disabled' : ''}`}
                onClick={() => piecesLongform.length > 0 && dispatch(setSelectedFeedType('longform'))}
              >
                Longform
              </button>
            </>
          )}
        </div>

        <hr className="feed-divider" />
  
        {isAuthenticated && (
          <>
            <button className="feed-filter-button" onClick={handleFilterPress}>
              {isFiltering ? "Edit filters" : "Filter "} <span className="filter-arrow">▼</span> 
            </button>
            <hr className="feed-divider" />
          </>
        )}
  
        {isLoading ? (
          <p className="feed-loading-message">Feed Loading...</p>
        ) : (
          activePieces.map((item, index) => (
            <div key={index} className="theFeedItem">
              <div className="authorAndLengthInfo">
                <div className="profileFeed-authorInfo" onClick={() => handleAuthorClick(item)} style={{ cursor: 'pointer' }}>
                  <picture>
                    {item.author_info.profile_image_webp && (
                      <source srcSet={item.author_info.profile_image_webp} type="image/webp" />
                    )}
                    {item.author_info.profile_image_jpeg && (
                      <source srcSet={item.author_info.profile_image_jpeg} type="image/jpeg" />
                    )}
                    <img 
                      src={item.author_info.profile_image_jpeg || item.author_info.vertical_image_jpeg || printingPressProfile} 
                      alt={item.piece.name}
                      className="authorPhoto"
                    />
                  </picture>
                  <span className="authorName">{item.piece.author_name}</span>
                </div>
                <span className="lengthIndicator">{item.piece.piece_genre}</span>
              </div>
  
              {supportsWebp && item.piece.piece_image_webp ? (
                <img 
                  key={feedType} // 🔥 Forces re-render when switching feeds
                  src={item.piece.piece_image_webp} 
                  alt="Cover Art" 
                  className="theFeed-pieceImage" 
                  onLoad={handleImageLoad}
                  onClick={() => goToPiecePage(item)}
                />
              ) : item.piece.piece_image_jpeg ? (
                <img 
                  key={feedType} // 🔥 Forces re-render when switching feeds
                  src={item.piece.piece_image_jpeg} 
                  alt="Cover Art" 
                  className="theFeed-pieceImage" 
                  onLoad={handleImageLoad}
                  onClick={() => goToPiecePage(item)}
                />
              ) : (
                item.piece.image && 
                <img 
                  key={feedType} // 🔥 Forces re-render when switching feeds
                  src={item.piece.image} 
                  alt="Cover Art" 
                  className="theFeed-pieceImage" 
                  onLoad={handleImageLoad}
                  onClick={() => goToPiecePage(item)}
                />
              )}
  
              <div className="pieceInfo">
                <div className="title-and-button-container">
                  <h3 className="clickable" onClick={() => goToPiecePage(item)}>
                    {item.piece.title}
                    {item.piece.is_serial && item.piece.serial_part_number && (
                      <span className="profileFeed-serial-part">
                        {" - a story in " + numberToWord(item.piece.serial_total_parts) + " parts"}
                      </span>
                    )}
                  </h3>
                  <button
                    className="read-piece-button"
                    onClick={() => item.piece.is_serial ? goToSeriesPage(item) : goToPiecePage(item)}
                  >
                    {item.piece.is_serial ? "View series" : "Read"}
                  </button>
                </div>
                <p>{item.piece.description}</p>
                <div className="genre-style-container">
                  <span className="genre-style-text">
                    {item.piece.is_serial && item.piece.serial_total_length
                      ? `${item.piece.serial_total_length.toLocaleString()} total words`
                      : `${item.piece.piece_length.toLocaleString()} words`
                    } - {item.stats.total_likes} <img src={heart} alt="Likes" className="iconista" /> - {item.stats.total_reads} <img src={reads} alt="Total Reads" className="iconista" />
                  </span>

                  <span className="genre-style-text">
                    Style: <span className="italic-text">{item.piece.piece_style}</span>
                  </span>
                </div>
                {item.links && item.links.length > 0 && (
                <>
                  <hr className="profileFeed-divider" />
                  <div className="promoted-books-text">Promoted books</div>
                  <BooksInPiece
                    feedItems={item.links}
                    prevRoute={prevRoute}
                    piece={item.piece}
                    author_info={item.author_info}
                    pieceViewHash={"someHashOrVariable"} 
                    clicketyType={"FEED"}
                  />
                </>
              )}
              <hr className="divider" />

              </div>
            </div>
          ))
        )}
  
      {!isLoading && !showLoading && piecesShorties.length === 0 && piecesLongform.length === 0 && (
        <div className="lowResultsMessage">
          <p>{getEmptyResultsMessage()}</p>
        </div>
      )}
      </div>
    </div>
  );
  
};

export default TheFeed;