import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import './Library.css';
import library from '../../assets/images/library.png'; // Make sure to import your feeding icon
import { apiInstance } from '../../utils/axiosInstance';// Adjust the import path as necessary
import failedbooks from '../../assets/images/book.png'; // Fallback image
import { fetchAuthSession } from 'aws-amplify/auth';
import bookImage from '../../assets/images/book.png'; 
import pieceImage from '../../assets/images/piece.png'; 
import { showGenericModal } from '../../store/modalSlice';
import { setSelectedTab, setLibraryData, addBookToLibrary } from '../../store/librarySlice';


import {useNavigate} from 'react-router-dom';

function Library() {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const selectedTab = useSelector(state => state.library.selectedTab);
  const readPosts = useSelector(state => state.library.readPosts);
  const likedPosts = useSelector(state => state.library.likedPosts);
  const interestBooks = useSelector(state => state.library.interestBooks);
  const readBooks = useSelector(state => state.library.readBooks);
  const ownedBooks = useSelector(state => state.library.ownedBooks);

  const hasFetchedLibrary = useSelector(state => state.library.hasFetchedLibrary);

  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const userId = useSelector((state) => state.user.userId);


  const MINIMUM_LOADING_TIME = 400; 

 
  useEffect(() => {
    const fetchData = async () => {
      const isLibraryEmpty = !readPosts.length && !likedPosts.length && !readBooks.length && !interestBooks.length && !ownedBooks.length;

      if (!isLibraryEmpty || !userId) return; 
      const startTime = Date.now();
      setIsLoading(true);
      setShowLoading(true);
  
      try {
        const session = await fetchAuthSession();
        const jwtToken = session.tokens.idToken.toString();
  
        const [piecesResponse, booksResponse, likesResponse] = await Promise.all([
          apiInstance.get('/clicks/latestuserpieces', { params: { user_id: userId, limit: 10 }, headers: { Authorization: `Bearer ${jwtToken}` } }),
          apiInstance.get('/clicks/user-books-summary', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          }),
          apiInstance.get('/clicks/latestpiecelikes', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          })
        ]);
  
        dispatch(setLibraryData({
          readPosts: piecesResponse.data || [],
          likedPosts: likesResponse.data || [],
          interestBooks: booksResponse.data.interest_books || [],
          readBooks: booksResponse.data.read_books || [],
          ownedBooks: booksResponse.data.owned_books || []
        }));

        dispatch({ type: 'library/setHasFetchedLibrary', payload: true });

  
        const endTime = Date.now();
        const loadTime = endTime - startTime;
  
        if (loadTime < MINIMUM_LOADING_TIME) {
          setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => setShowLoading(false), 500);
          }, MINIMUM_LOADING_TIME - loadTime);
        } else {
          setIsLoading(false);
          setTimeout(() => setShowLoading(false), 500);
        }
  
      } catch (error) {
        console.error('Error fetching library data:', error);
        setIsLoading(false);
        setTimeout(() => setShowLoading(false), 500);
      }
    };
    setIsLoading(false)
    fetchData();
  }, [userId, hasFetchedLibrary, dispatch]);
  


  const goToPreviousPiece = (hash) => {
    window.scrollTo(0, 0);

  
    navigate(`/piece/${hash}`, { 
      state: { 
        prevRoute: `/library`
      } 
    });
  };

  const generateHash = (length) => {
    return crypto.getRandomValues(new Uint8Array(length))
      .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
      .slice(0, length);
  };
  
  const generateCustomTimestamp = () => {
    return Date.now().toString(36);
  };
  
  

  const handleBookClick = (book, position, clicketyType) => {

    let derivedStatus = "";

    if (clicketyType === "READ-LIBRARY") {
      derivedStatus = "READ";
    } else if (clicketyType === "INTEREST-LIBRARY") {
      derivedStatus = "WANT";
    } else if (clicketyType === "OWNED-LIBRARY") {
      derivedStatus = "OWN";
    }

    dispatch(showGenericModal({
      modalType: 'BOOK_MODAL',
      modalProps: {
        book: {
          ...book,
          book_author_name: book.author, 
          book_title: book.title,
          book_image_url: book.image,
          user_status: derivedStatus 
        },
        prevRoute: 'library',
        position,  // Now uses actual position in list
        piece: '',
        author_info: { profile_hash: book.profile_hash, name: '' },
        pieceViewHash: 'LIBRARY',
        clicketyType, // Dynamically set based on the section
        onLibraryStatusUpdate: (updatedBook) => {
          dispatch(addBookToLibrary({
            book: updatedBook,
            category: updatedBook.status
          }));
        }
      }
    }));
  };
  
  

  const renderReadPosts = () => {
    if (isLoading && readPosts.length === 0) {
      return <p className="library__loading-message">Fetching your read pieces...</p>;
    }
    
    if (readPosts.length === 0) {
      return <p className="library__empty-message">No pieces read - go get-a-reading</p>;
    }

    return (
      <div className="library__post-feed">
        {readPosts.map((post, index) => (
          <div key={index} className="library__post-item" onClick={() => goToPreviousPiece(post.piece_hash)}>
            <img
              src={post.piece_image_jpeg || post.piece_image_webp || post.piece_image}
              alt={post.title}
              className="library__post-image"
            />
            <div className="library__post-details">
              <div className="library__post-title-container">
                <span className="library__post-title">
                  {post.piece_title}
                  {post.serial_part_number && (
                    <span className="library__post-part-number"> - Part {post.serial_part_number}</span>
                  )}
                </span>
              </div>
              <div className="library__post-author">{post.piece_writer_name}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderLikedPosts = () => {
    if (isLoading && likedPosts.length === 0) {
      return <p className="library__loading-message">Fetching your liked pieces...</p>;
    }
    
    if (likedPosts.length === 0) {
      return <p className="library__empty-message">No pieces liked - go get-a-liking</p>;
    }
    

    return (
      <div className="library__post-feed">
        {likedPosts.map((post, index) => (
          <div key={index} className="library__post-item" onClick={() => goToPreviousPiece(post.piece_hash)}>
            <img
              src={post.piece_image_jpeg || post.piece_image_webp || post.piece_image}
              alt={post.title}
              className="library__post-image"
            />
            <div className="library__post-details">
              <div className="library__post-title-container">
                <span className="library__post-title">
                  {post.piece_title}
                  {post.serial_part_number && (
                    <span className="library__post-part-number"> - Part {post.serial_part_number}</span>
                  )}
                </span>
              </div>
              <div className="library__post-author">{post.piece_writer_name}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const RetryableImage = ({ src, alt, className, maxRetries = 15, interval = 1000, fallback }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(fallback); // Initially set to fallback image

    useEffect(() => {
      const img = new Image();
      
      img.onload = () => {
        setImageSrc(src); // Set to original source on successful load
      };
      
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount(prevCount => prevCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`; // Bypass cache with retry param
          }, interval);
        } else {
          setImageSrc(fallback); // Use fallback after max retries
        }
      };
      
      img.src = src; // Start loading the original image
      
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval, fallback]);

    return <img src={imageSrc} alt={alt} className={className} />;
  };


  const renderBookFeed = (books, emptyMessage, clicketyType) => {
    if (isLoading && books.length === 0) {
      return <p className="library__loading-message">Fetching books...</p>;
    }
    
    if (books.length === 0) {
      return <p className="library__empty-message">{emptyMessage}</p>;
    }
  
    return (
      <div className="library__book-feed">
        {books.map((book, index) => (
          <div 
            key={index} 
            className="library__book-item" 
            onClick={() => handleBookClick(book, index, clicketyType)} // Pass clicketyType
          >
            <RetryableImage className="library__book-image" src={book.image} alt={book.title} fallback={failedbooks} />
            <div className="library__book-details">
              <div className="library__book-title">{book.title}</div>
              <div className="library__book-author">{book.author}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  

  return (
    <div className="library">
      <Header />
      {/* {showLoading ? (
        <div className={`loading-screen ${!isLoading ? 'fade-out' : ''}`}>
          <img src={library} alt="Loading" className="loading-icon" />
        </div>
      ) : ( */}
        <>
        <div className="library-mode-toggle-container">
        <button
            className={`pill-button-library ${selectedTab === 'books' ? 'active' : ''}`}
            onClick={() => dispatch(setSelectedTab('books'))}
          >
            Books
          </button>
          <button
            className={`pill-button-library ${selectedTab === 'pieces' ? 'active' : ''}`}
            onClick={() => dispatch(setSelectedTab('pieces'))}
          >
            Pieces
          </button>
        </div>




          {/* Dynamic Content */}
          {selectedTab === 'books' ? (
            <div> 
              <div className="library__section">
                <h3 className="library__section-title">Books you've read</h3>
                {renderBookFeed(readBooks, "You haven't read a single book? I'm not sure this is the place for you", "READ-LIBRARY")}
              </div>
              <div className="library__section">
                <h3 className="library__section-title">Books you've shown interest in</h3>
                {renderBookFeed(interestBooks, "No books you want to read - go find some!", "INTEREST-LIBRARY")}
              </div>
              <div className="library__section">
                <h3 className="library__section-title">Books you own (but haven't read)</h3>
                {renderBookFeed(ownedBooks, "You don't have a pile of books to read?", "OWNED-LIBRARY")}
              </div>
            </div>
          ) : (
            <>
              <div className="library__section">
                <h3 className="library__section-title">Pieces you've liked</h3>
                {renderLikedPosts()}
              </div>
              
              <div className="library__section">
                <h3 className="library__section-title">Pieces you've read</h3>
                {renderReadPosts()}
              </div>
            </>
          )}

        </>
      {/* )} */}
    </div>
  );
}

export default Library;