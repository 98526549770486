import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import BookCard from '../BookCard/BookCard'; // Adjust the path as necessary
import ReusableBox from '../ReusableBoxContainer/ReusableBoxContainer'; // Adjust the path as necessary
import './BookModalContent.css'; // Import the CSS file here
import { apiInstance } from '../../utils/axiosInstance'; 
import { useSelector } from 'react-redux';
import { fetchAuthSession } from 'aws-amplify/auth';
import closeIcon from '../../assets/images/circle-xmark-regular.svg'; 
import { hideGenericModal } from '../../store/modalSlice';


const BookModalContent = ({ book, onStatusUpdate, onLibraryStatusUpdate, prevRoute, position,piece, author_info, pieceViewHash, clicketyType = "PIECE" , onClose}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  console.log('spook',book);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownOpen]); // ✅ Add dropdownOpen as a dependency

  console.log('Book:', book);
  const [status, setStatus] = useState(book.user_status === 'NONE' ? '' : (book.user_status || ''));


  function generateCustomTimestamp() {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month (01-12)
    const day = now.getDate().toString().padStart(2, '0'); // Day of the month (01-31)
    const hour = now.getHours().toString().padStart(2, '0'); // Hour (00-23)
  
    return `${year}${month}${day}${hour}`;
  }

  const onBuyClick = () => {
    // Open the book link directly in response to the user's click
    const generateHash = (length) => {
      return crypto.getRandomValues(new Uint8Array(length))
        .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')  // Base 36 encoding
        .slice(0, length);  // Ensure the hash is exactly 'length' characters
    };
    
    const timestamp = generateCustomTimestamp();// Current time as a simple tracker
    
    const uniqueHash = generateHash(12);     // Generate a 12-character unique hash

    const clickType = 'P'; 

    const paddedPosition = position.toString().padStart(2, '0'); 
    
    const sid = `${uniqueHash}-${clickType}-${paddedPosition}-${piece.piece_hash}-${author_info.profile_hash}-${timestamp}`;
    
    // URL encode the SID to handle special characters
    const encodedSid = encodeURIComponent(sid);
    
    console.log('Generated SID:', sid);
    console.log('Encoded SID:', encodedSid);

    
    // Append the SID to the product link URL
    const url = new URL(book.affiliate_url);
    if (book.affiliate_id === 1) {
      // CJ Affiliate
      url.searchParams.append('sid', encodedSid);
    } else if (book.affiliate_id === 2) {
      // Awin Affiliate
      url.searchParams.append('clickref', encodedSid);
    } // Assuming 'sid' is the query parameter required by your affiliate program
    
    // Open the book link with the SID directly in response to the user's click
    window.open(url.toString(), '_blank');

    console.log(url.toString());
    console.log(sid.length);
    console.log(clicketyType, 'sciclikc');

    const affiliateClickData = {
      piece_view_hash: pieceViewHash,
      unique_hash: uniqueHash,
      piece_id: piece.piece_id,
      piece_hash: piece.piece_hash,
      link_id: book.link_id,
      referrer_page: prevRoute,
      position: position,
      affiliate_id: book.affiliate_id,
      affiliate_url: book.affiliate_url,
      book_author_name: book.book_author_name,
      book_image_url: book.book_image_url,
      book_raw_title: book.book_raw_title,
      book_sub_title: book.book_sub_title,
      book_title: book.book_title,
      format: book.format,
      product_link_url: book.product_link_url,
      publishing_user: book.publishing_user,
      consuming_user: userId,
      reason_why: book.reason_why,
      retailer_id: book.retailer_id,
      piece_writer_name: author_info.name,
      profile_hash: author_info.profile_hash,
      referrer_external: document.referrer,
      click_type: clicketyType
    };
  
    // Make the API call without waiting for its result
    apiInstance.post('/clicks/affiliate/create', affiliateClickData)
    .then(response => console.log('API Call Successful:', response.data))
    .catch(error => console.error('Error in API Call:', error));
  };

  const handleStatusChange = async (newStatus) => {
    
    const updatedStatus = newStatus === "REMOVE" ? "NONE" : newStatus;
    setDropdownOpen(false);
  
    try {
      const session = await fetchAuthSession(); // Fetch the JWT session
      const jwtToken = session.tokens.idToken.toString();
  
      const payload = {
        user_id: userId,
        author: book.book_author_name,
        title: book.book_title,
        external_product_id: book.external_product_id,
        status: updatedStatus,
        retailer_id: book.retailer_id,
        retailer_name: book.retailer_name,
        image_link: book.book_image_url,
        affiliate_id: book.affiliate_id,
        product_link_url: book.product_link_url,
        affiliate_url: book.affiliate_url,
        profile_hash: author_info.profile_hash

      };

      setStatus(newStatus === "REMOVE" ? "" : newStatus);
  
      // Make the API call with the JWT token in the headers
      await apiInstance.post('/users/book/change-status', payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (onStatusUpdate) {
        onStatusUpdate({
          ...book,
          status: updatedStatus
        });
      }

      

      if (onLibraryStatusUpdate) {
        onLibraryStatusUpdate({ ...book, status: updatedStatus });
        console.log('works at least getting here');
      }
  
      console.log('Status changed successfully');
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="bookModalContent">
      <div className="modalHeader">
        <button className="bookModalCloseButton" onClick={() => dispatch(hideGenericModal())}>
          <img src={closeIcon} alt="Close" />
        </button>
      </div>
      <BookCard 
        book={book}
      />
      {!isAuthenticated ? (
        // UNAUTHENTICATED => ONLY 1 BUTTON
        <button
          className="buttoninbox boldButton fullWidthItem"
          onClick={onBuyClick}
        >
          Buy the book
        </button>
      ) : (
        // AUTHENTICATED => 2-ITEM LAYOUT
        <div className="actionRowFullWidth">
          {/* Left: Buy Button */}
          <button 
            className="buttoninbox boldButton halfWidthItem" 
            onClick={onBuyClick}
          >
            Buy the book
          </button>

          {/* Right: “Add to Shelf” Dropdown */}
          <div className="bookStatusDropdownWrapper halfWidthItem" ref={dropdownRef}>
            {/* 
              If no status chosen yet, show "Add to shelf" with a more muted style.
              If a status is chosen, display that status text. 
              You can map the code to more user-friendly text if you like.
            */}
            <button
              className={`buttoninbox boldButton bookStatusDropdownTrigger ${
                status ? "" : "inactive"
              }`}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {status === ""
                ? "Add to shelf"
                : status === "READ"
                  ? "Have read"
                  : status === "OWN"
                    ? "Own"
                    : status === "WANT"
                      ? "Interested in"
                      : status === "REMOVE"
                        ? "Remove from current shelf"
                        : status
              }
              <span className="bookStatusDropdownTriangle">&#9662;</span>
            </button>

            {dropdownOpen && (
              <div className="bookStatusDropdown">
                <ul>
                  <li
                    onClick={() => handleStatusChange("READ")}
                    className={status === "READ" ? "selected" : ""}
                  >
                    Have read
                  </li>
                  <li
                    onClick={() => handleStatusChange("OWN")}
                    className={status === "OWN" ? "selected" : ""}
                  >
                    Own (but unread)
                  </li>
                  <li
                    onClick={() => handleStatusChange("WANT")}
                    className={status === "WANT" ? "selected" : ""}
                  >
                    Want to read
                  </li>
                  {status && (
                    <li onClick={() => handleStatusChange("REMOVE")}>Remove from shelf</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {book.reason_why && book.reason_why.trim().length > 0 && (
        <ReusableBox 
          title="Book's association to piece:"
          description={book.reason_why}
          centerTitle={false}
        />
      )}


    </div>
    
  );
};

export default BookModalContent;


