import React, { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ReaderFooter.css';
import feedIcon from '../../assets/images/feeding.png';
import libraryIcon from '../../assets/images/library.png';
import findIcon from '../../assets/images/find.png';
import { useDispatch } from 'react-redux';
import { resetFilters } from '../../store/theFeedSlice';

const ReadersFooter = forwardRef(({ isAuthenticated }, ref) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isFocused = (path) => {
    if (path === '/readers') {
      return location.pathname === path || location.pathname === '/readers/';
    }
    return location.pathname.startsWith(path);
  };

  const handleFeedNavigation = () => {
    if (location.pathname !== '/readers') {
      // dispatch(resetFilters());
      window.scrollTo(0, 0);
    }
  };

  const renderLabel = (label, path) => {
    const focusedClass = isFocused(path) ? 'visible' : 'hidden';
    return (
      <div className="label-container">
        <span className={`arrow left ${focusedClass}`}>&nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;</span>
        <span className="label">{label}</span>
        <span className={`arrow right ${focusedClass}`}>&nbsp;&lt;&#8209;&#8209;&#8209;&nbsp;&nbsp;</span>
      </div>
    );
  };

  return (
    <footer ref={ref} className="readers-footer">
      <div className="icons-container">
        {/* Feed Link */}
        <Link to="/readers" className="footer-link" onClick={handleFeedNavigation}>
          <div className={isFocused('/readers') ? 'footer-item focused' : 'footer-item'}>
            <img src={feedIcon} alt="Feed" className="footer-icon" />
            <span>{renderLabel('Feed', '/readers')}</span>
          </div>
        </Link>

        {/* Find Link */}
        <Link to="/readers/find" className="footer-link">
          <div className={isFocused('/readers/find') ? 'footer-item focused' : 'footer-item'}>
            <img src={findIcon} alt="Find" className="footer-icon" />
            <span>{renderLabel('Find', '/readers/find')}</span>
          </div>
        </Link>

        {/* Library Link (Only for Authenticated Users) */}
        {isAuthenticated && (
          <Link to="/readers/library" className="footer-link">
            <div className={isFocused('/readers/library') ? 'footer-item focused' : 'footer-item'}>
              <img src={libraryIcon} alt="Library" className="footer-icon" />
              <span>{renderLabel('Library', '/readers/library')}</span>
            </div>
          </Link>
        )}
      </div>
    </footer>
  );
});

export default ReadersFooter;
