import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutReadersWords.css';
import turbo from '../../assets/images/me-profile.png';
import birdie from '../../assets/images/piece-summary.png';
import bookswide from '../../assets/images/books-wide.png';
import thirdLeg from '../../assets/images/bookstore-about.png';
import BookWorld from '../../assets/images/BookWorld.png';


function AboutReadersWords({ isAuthenticated, userRole }) {

    const handleInfo = (route) => {
        console.log(`Navigating to ${route}`);
        window.scrollTo(0, 0);
        navigate(route);
      };

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    const renderCTA = () => {
        if (!isAuthenticated) {
          return (
            <div className="cta-section">
              <button className="cta-button" onClick={() => handleNavigation('/login')}>
                Create your account / log in
              </button>
            </div>
          );
        } else {
            return (
                <div className="cta-section">
                  <button className="cta-button" onClick={() => handleNavigation('/readers')}>
                    Go to reader's homepage
                  </button>
                </div>
              );
        }
      };

      const renderTrying = () => {
        if (!isAuthenticated) {
          return (
            
            <div className="account-creation-section">
                <hr className="subtle-line-aboutwords" />
                
                <h3>Want to take a look around? </h3>
                <p>
                    At the link below, you can explore a pared back version of the reader’s experience to get a feel for the stories, books, and connections ShorterStories offers.
                </p>
                <button className="cta-button" onClick={() => handleInfo("/readers")}>
                    Enter the reader's site 
                </button>
                <div className="image-birdie-container">
                    <img src={BookWorld} alt="BookWorld" className="centered-birdie-image" />
                </div>

            </div>
          );
        } else {
            return ;
        }
      };


    return (
        <div className="about-readers-words">
            <h3>ShorterStories: A new kind of bookstore</h3>
            <p>Most book discovery apps focus on you connecting with friends, but what do your friends know about books? With ShorterStories you connect directly with writers, their stories and the books that inspire them.</p>
            <p>Think of it as an organic bookstore, a personal library, a literary home, where readers get to know writers and engage with them on their level. It’s browsing made personal—and it’s how reading should feel. </p>
            {renderCTA()}
            <img src={thirdLeg} alt="book-wide" className="uninlinable-image" />

            <hr className="subtle-line-aboutwords" />
            <h3>What you get on ShorterStories</h3>
            <p>As a reader, ShorterStories gives you straightforward access to new voices and fresh content. Browse short works from a variety of writers, follow the ones who speak to you, and find books that align with your tastes. It’s all direct—no noisy ads, no gimmicks—just a clear path to discovering your next great read.</p>
            <p><strong>1. Quality reading:</strong></p>
            
            
            <ul>
                <li><strong>Diverse content:</strong> Explore everything from 50-word erotic poems to 5,000-word fantastical short stories.</li>
                <li><strong>Serial publishing:</strong> Follow stories week-to-week, enjoying the anticipation of serialized content.</li>
            </ul>
            
            <div className="profile-section">
                <p><strong>2. Writer connections:</strong></p>
                <img src={turbo} alt="Profile-photo" className="inlinable-image" />
            </div>
            <ul>
                <li><strong>Follow along:</strong> Keep up with your favorite writers.</li>
                <li><strong>Behind the veil:</strong> Get insights into the writing process and inspirations of writers.</li>
            </ul>
            <p><strong>3. Book discovery:</strong></p>
            <img src={bookswide} alt="book-wide" className="uninlinable-image" />
            <ul>
                <li><strong>Inspiration:</strong> Discover what books inspired your favorite authors.</li>
                <li><strong>Rabbit trails:</strong> Take the paths that lead from one writer to another and discover new voices and books.</li>
            </ul>
            

            <p><strong>4. Support the industry:</strong> When you buy books through ShorterStories, writers earn money: they receive a percentage of the sale. This creates a direct financial link between you and who you follow on the platform.</p>
            {renderTrying()}
            {/* <p><strong>5. Evolving Platform:</strong> We're constantly improving, ensuring you always have access to ever cooler stuff.</p> */}
        </div>
    );
}

export default AboutReadersWords;