import React, { useState, useEffect } from 'react';
import './BooksInPiece.css';
import { useDispatch } from 'react-redux';
import { showGenericModal } from '../../store/modalSlice';
import failedbooks from '../../assets/images/book.png';

const BooksInPiece = ({ feedItems, prevRoute, piece, author_info, pieceViewHash, clicketyType = "PIECE" }) => {
  const [items, setItems] = useState(feedItems);

  useEffect(() => {
    setItems(feedItems);
  }, [feedItems]);

  const dispatch = useDispatch();
  if (!feedItems || feedItems.length === 0) {
    return null;
  }

  const handleStatusUpdate = (updatedBook) => {
    console.log('status updated', updatedBook.status);
    setItems(items.map(item =>
      (item.book_title === updatedBook.book_title && item.book_author_name === updatedBook.book_author_name)
        ? { ...item, user_status: updatedBook.status }
        : item
    ));
  };

  const handleBookClick = (book, index) => {
    dispatch(showGenericModal({
      modalType: 'BOOK_MODAL',
      modalProps: { book, onStatusUpdate: handleStatusUpdate, prevRoute, position: index, piece, author_info, pieceViewHash, clicketyType }
    }));
  };

  let repeatingBooks = [];
  while (repeatingBooks.length < 4) {
    repeatingBooks = repeatingBooks.concat(items);
  }

  const RetryableImage = ({ src, alt, className, author, maxRetries = 15, interval = 1000 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(failedbooks); // Initially set to fallback image

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setImageSrc(src);
      };
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            console.log(`Retrying image load: Attempt ${retryCount + 1}`);
            setRetryCount(retryCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`;
          }, interval);
        } else {
          setImageSrc(failedbooks); // Final fallback
        }
      };
      img.src = `${src}?retry=${retryCount}`;

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval]);

    const isFallback = imageSrc === failedbooks;

    return (
      <div className="retryableImageWrapper">
        <img src={imageSrc} alt={alt} className={className} />
        {isFallback && (
          <div className="fallbackOverlay">
            {author.split(" ").map((word, i) => (
              <div key={i} className="authorNameDifferent">{word}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bookContainer">
      <div className="bookItemsContainer">
        {repeatingBooks.slice(0, 4).map((item, index) => (
          <div
            className="bookItem"
            key={index}
            onClick={() => handleBookClick(item, index)}
          >
            <RetryableImage
              className="bookImage"
              src={item.book_image_url}
              alt={item.book_title}
              author={item.book_author_name} // Pass the author name for overlay
              maxRetries={15}
              interval={1000}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BooksInPiece;
