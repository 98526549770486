import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { apiInstance } from '../../utils/axiosInstance';
import { Helmet } from 'react-helmet';
import Header from '../../components/HeaderTheFeed/HeaderTheFeed';
import './Series.css';
import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import heart from '../../assets/images/heart.svg';
import { checkAuthenticationStatus } from '../../store/userSlice';
import reads from '../../assets/images/read-book-icon.svg';
import BooksInPiece from '../../components/BooksInPiece/BooksInPiece';

const SeriesPage = () => {
  const { serialFirstProfileHash } = useParams();
  const navigate = useNavigate();
  const [seriesData, setSeriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedUser, setCheckedUser] = useState(false); // ✅ Track if we've checked authentication
  const userId = useSelector(state => state.user.userId);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      if (!isAuthenticated) {
        await dispatch(checkAuthenticationStatus());
      }
      setCheckedUser(true); // ✅ Mark that we've checked
    };

    checkAuth();
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (checkedUser) {
      fetchSeries(userId);
    }
  }, [checkedUser, userId, serialFirstProfileHash]); // ✅ Runs once after we've checked user

  const fetchSeries = (userId) => {
    const params = userId ? { consumer_id: userId } : {}; // ✅ Only add if exists

    console.log("🔥 Fetching series with params:", params);

    apiInstance.get(`/pieces/serial_feed/${serialFirstProfileHash}`, { params })
      .then(response => {
        console.log("✅ Series fetched successfully:", response.data);
        setSeriesData(response.data.feed_data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("❌ Error fetching series data:", error.response ? error.response.data : error.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="seriesPage">
      <Helmet>
        <title>Series | ShorterStories</title>
        <meta name="description" content="Explore serialized stories on ShorterStories, where each part unfolds the narrative in a compelling way." />
      </Helmet>

      <Header />

      {isLoading ? (
        <p>Loading series...</p>
      ) : (
        <>
          {seriesData.length > 0 && (
            <div className="series-header-container">
              <div className="series-header">
                <h1>{seriesData[0].piece.title}</h1>

                <div className="series-authorAndInfo">
                  <div className="series-authorDetails" onClick={() => navigate(`/writer/${seriesData[0].author_info.profile_hash}`)}>
                    <img
                      src={seriesData[0].author_info.profile_image_jpeg || printingPressProfile}
                      alt={seriesData[0].author_info.name}
                      className="series-authorPhoto"
                    />
                    <p>
                      by <span className="series-author-link">{seriesData[0].author_info.name}</span>
                    </p>
                  </div>
                </div>

                <div className="series-description-container">
                  <p className="series-description">{seriesData[0].piece.description}</p>
                </div>
                <p className="series-totalWords">~ {seriesData[0].piece.serial_total_length} total words in {seriesData.length} parts ~</p>
                <hr className="series-dividers" />
              </div>
            </div>
          )}

          <div className="series-contentContainer">
          {seriesData.map((item, index) => (
            <React.Fragment key={index}>
              <div className="series-item">
                <h3 className="series-title">{item.piece.title + " - part " + item.piece.serial_part_number}</h3>

                <img
                  src={item.piece.piece_image_jpeg || printingPressProfile}
                  alt="Cover Art"
                  className="series-pieceImage"
                  onClick={() => navigate(`/piece/${item.piece.piece_hash}`)}
                />

                <div className="series-info">
                  <div className="series-topRow">
                    <div className="series-style">
                      Style: <span className="series-italic">{item.piece.piece_style}</span>, 
                      Genre: <span className="series-italic">{item.piece.piece_genre}</span>
                    </div>
                    <button className="series-readButton" onClick={() => navigate(`/piece/${item.piece.piece_hash}`)}>
                      {(item.piece.publishing_user === userId)
                        ? 'Read again'
                        : (item.piece.has_read ? 'Read again' : 'Read')}
                    </button>
                  </div>

                  <div className="series-genreStats">
                    <span className="series-stats">
                      {item.piece.piece_length} words – {item.stats.total_likes} <img src={heart} alt="Likes" className="series-icon" /> – {item.stats.total_reads} <img src={reads} alt="Reads" className="series-icon" />
                    </span>
                    {(item.piece.publishing_user === userId || item.piece.has_read || item.piece.has_liked) && (
                      <div className="series-userFlagsWrapper">
                        <div className="series-userFlags">
                          {item.piece.has_liked
                            ? <span className="series-flag">You read and ♥ this piece</span>
                            : <span className="series-flag">You read this piece <img src={reads} alt="Read icon" className="series-icon" /></span>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Promoted Books Section */}
                {item.links && item.links.length > 0 && (
                  <>
                    <hr className="series-divider" />
                    <div className="promoted-books-text">Promoted books</div>
                    <BooksInPiece
                      feedItems={item.links}
                      piece={item.piece}
                      author_info={item.author_info}
                      pieceViewHash={"someHashOrVariable"}
                      clicketyType={"SERIES"}
                    />
                  </>
                )}

                <hr className="series-divider" />
              </div>

              {/* 👇 Insert login prompt every 2 items if user not logged in */}
              {!userId && (index + 1) % 2 === 0 && index + 1 < seriesData.length && (
                <div className="series-loginReminder">
                  <p className="series-loginReminder-text">
                    Want to save where you’re up to? Creating an account keeps track of what you’ve read — so you can pick up right where you left off.
                  </p>
                  <button className="series-loginReminder-button" onClick={() => navigate('/login')}>
                    Create account / Log in
                  </button>
                  <hr className="series-loginReminder-divider" />
                </div>
              )}
            </React.Fragment>
          ))}

          </div>
        </>
      )}
    </div>
  );
};

export default SeriesPage;
