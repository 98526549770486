import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  query: '',
  writersQuery: '',
  booksQuery: '',
  selectedTab: 'writers',
  results: { writers: [], books: [] },
  responseMessage: { writers: '', books: '' },
  randomFetched: { writers: false, books: false },
  loading: false,  // 🔥 New state for tracking search
};

const findSlice = createSlice({
  name: 'find',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      const { tab, query } = action.payload;
      if (tab === 'writers') {
        state.writersQuery = query;
      } else {
        state.booksQuery = query;
      }
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    setResponseMessage: (state, action) => {
      state.responseMessage = action.payload;
    },
    setRandomFetched: (state, action) => {
      state.randomFetched = { ...state.randomFetched, ...action.payload };
    },
    setLoading: (state, action) => {   // 🔥 New action
      state.loading = action.payload;
    },
    resetFindState: () => initialState, // Reset all state when logging out
  },
});

export const {
  setQuery,
  setSelectedTab,
  setResults,
  setResponseMessage,
  setRandomFetched,
  resetFindState, 
  setLoading 
} = findSlice.actions;

export default findSlice.reducer;
