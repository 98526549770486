import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'aws-amplify/auth';
import { checkAuthenticationStatus, setAuthChecked } from '../../store/userSlice';
import { resetAuthorProfile } from '../../store/authorProfileSlice';
import { clearSelections } from '../../store/taggingSlice';
import { resetPublishingProcess } from '../../store/publishingSlice';
import { resetHomeStats } from '../../store/homeSlice';
import { resetFilters, setShouldApplyFilters, setSelectedFeedType } from '../../store/theFeedSlice';
import pancakeImage from '../../assets/images/menufour.jpeg';
import { showGenericModal } from '../../store/modalSlice';
import {resetLibraryState} from '../../store/librarySlice';
import './HeaderTheFeed.css';

function HeaderFeed() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isFiltering = useSelector(state => state.theFeed.isFiltering);
  const [menuVisible, setMenuVisible] = useState(false);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  const handlePancakeClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleInfo = (route) => {
    navigate(route);
    setMenuVisible(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuVisible &&
        !event.target.closest('.pancake-dropdown') &&
        !event.target.closest('.pancake-buttons')
      ) {
        setMenuVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  const handleLogout = async () => {
    try {
      setMenuVisible(false);
      

      await signOut();
      dispatch(setAuthChecked(false));
      dispatch(checkAuthenticationStatus());
      navigate("/");
      dispatch(resetAuthorProfile());
      dispatch(clearSelections());
      dispatch(resetPublishingProcess());
      dispatch(resetHomeStats());
      dispatch(resetLibraryState());
      dispatch(resetFilters());
      dispatch(setShouldApplyFilters(true));
      dispatch(setSelectedFeedType('shorties'));
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  console.log('isFiltering', isFiltering);

  const navigateBasedOnPage = () => {
    if (location.pathname.startsWith('/piece/')) {
      navigate('/');
      return;
    }
    switch(location.pathname) {
      case '/writers/profile':
        navigate('/');
        break;
      default:
        navigate('/');
    }
  };

  const handleFilterPress = () => {
    dispatch(showGenericModal({
      modalType: 'THE_FEED_OPTIONS',
      modalProps: {}
    }));
  };

  return (
    <header className="header-main">
      <div className="header-content">
        <h1 onClick={navigateBasedOnPage} style={{ cursor: 'pointer' }}>
          ShorterStories.
        </h1>
        
        <div className="pancake-button">
          <button onClick={handlePancakeClick} aria-label="Menu">
            {isFiltering ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 64 64" id="filter-alt">
                <path stroke="#343E4D" strokeMiterlimit="10" strokeWidth="2" d="M50.6899 32H56.3199M7.67993 32H38.6899M26.54 15.97H56.32M7.67993 15.97H14.5599M35 48.03H56.32M7.67993 48.03H22.9999M20.55 21.6599C23.8638 21.6599 26.55 18.9736 26.55 15.6599 26.55 12.3462 23.8638 9.65991 20.55 9.65991 17.2363 9.65991 14.55 12.3462 14.55 15.6599 14.55 18.9736 17.2363 21.6599 20.55 21.6599zM44.6899 38C48.0036 38 50.6899 35.3137 50.6899 32 50.6899 28.6863 48.0036 26 44.6899 26 41.3762 26 38.6899 28.6863 38.6899 32 38.6899 35.3137 41.3762 38 44.6899 38zM29 54.03C32.3137 54.03 35 51.3437 35 48.03 35 44.7163 32.3137 42.03 29 42.03 25.6863 42.03 23 44.7163 23 48.03 23 51.3437 25.6863 54.03 29 54.03z"></path>
              </svg>
            ) : (
              <img src={pancakeImage} alt="Menu" />
            )}
          </button>
          {menuVisible && (
            <div className="pancake-dropdown">
              <ul>
                {isAuthenticated ? (
                  <li onClick={handleLogout}>Log Out</li>
                ) : (
                  <li onClick={() => handleInfo("/login")}>
                    Create account / Log in
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default HeaderFeed;