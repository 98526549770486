import React, { useState, useEffect } from 'react';
import './TagBookEditModalOneAndHalf.css';
import { useSelector, useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { 
  clearSelections, 
  setChosenRetailer, 
  setChosenFormat, 
  setBooksOnSale, 
  setChosenBook,
  setFormatOptions,
  setBnBooksOnSale,
  clearBnBooksOnSale
} from '../../store/taggingEditSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';
import qs from 'qs'; 


apiInstance.defaults.paramsSerializer = params => {
  return qs.stringify(params, {encode: false});
};

const TagBookModalOneAndHalf = () => {
  const dispatch = useDispatch();
  const chosenBook = useSelector((state) => state.taggingEdit.chosenBook);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const chosenFormat = useSelector((state) => state.taggingEdit.chosenFormat);
  const formatOptions = useSelector((state) => state.taggingEdit.formatOptions);
  const bnBooksOnSale = useSelector((state) => state.taggingEdit.bnBooksOnSale);


  const getJwtToken = async () => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();
    return jwtToken;
  };

  console.log('Chosen book:', chosenBook);

  useEffect(() => {
    // Auto-select Barnes & Noble if available, otherwise Books-A-Million
    if (chosenBook.available_at_BN) {
      setSelectedRetailer('BN');
    } else if (chosenBook.available_at_BAM) {
      setSelectedRetailer('BAM');
    }
  }, [chosenBook]);

  const handleRetailerChange = (event) => {
    setSelectedRetailer(event.target.value);
  };

  const handleNextStep = async () => {
    if (selectedRetailer === 'BN') {
      dispatch(clearBnBooksOnSale());
      fetchBookDetails();
      nextModal('TAG_BOOK_TWO_BN_EDIT');
    } else if (selectedRetailer === 'BAM') {
      fetchBamBookDetails();
      nextModal('TAG_BOOK_TWO_EDIT');
      console.log('BAM API call completed');
    }
  };

  const fetchBookDetails = async () => {
    try {
      const jwtToken = await getJwtToken();
      const response = await apiInstance.get('/retrieve_books/bn', {
        params: {
          title: chosenBook.bn_title.toLowerCase(),
          author: chosenBook.author.toLowerCase()
        },
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });
      console.log('API Response:', response.data);
      dispatch(setBnBooksOnSale(response.data));
    } catch (error) {
      console.error('Error fetching book details:', error);
    }
  };

  const fetchBamBookDetails = async () => {
    try {
      const jwtToken = await getJwtToken();
      const response = await apiInstance.get('/retrieve_books/bam', {
        params: {
          title: chosenBook.bam_title.toLowerCase(),
          author: chosenBook.author.toLowerCase()
        },
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });
      console.log('BAM API Response:', response.data);

      dispatch(setBooksOnSale(response.data));

      // We're not dispatching the data yet, just logging it
    } catch (error) {
      console.error('Error fetching BAM book details:', error);
    }
  };

  const nextModal = (nextModalType) => {
    if (selectedRetailer) {
      dispatch(setChosenRetailer(selectedRetailer));
      dispatch(hideGenericModal());
      dispatch(showGenericModal({
        modalType: nextModalType,
      }));
    }
  };

  const prevModal = () => {
    dispatch(setChosenBook(null));
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK_EDIT',
    }));
  };

  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles">Tagging a book:</h2>
        <div className="forbreaks-hopes">
          <label className="sectionTitler">2. Choose Retailer:</label>
        </div>
        {chosenBook && (
          <div className="book-info">
            <div className="retailer-options">
              {chosenBook.available_at_BN && (
                <label>
                  <input
                    type="radio"
                    value="BN"
                    checked={selectedRetailer === 'BN'}
                    onChange={handleRetailerChange}
                  />
                  Barnes & Noble
                </label>
              )}
              {chosenBook.available_at_BAM && (
                <label>
                  <input
                    type="radio"
                    value="BAM"
                    checked={selectedRetailer === 'BAM'}
                    onChange={handleRetailerChange}
                  />
                  Books-A-Million
                </label>
              )}
            </div>
          </div>
        )}
        <div className="button-container">
          <button 
            className="infosButton-details-next-edit" 
            
            onClick={handleNextStep}
            disabled={!selectedRetailer}
          >
            Next step ---&gt;
          </button>            
          <button 
            className="infosButton-details-prev" 
            onClick={prevModal}
          >
            &lt;--- Previous step
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagBookModalOneAndHalf;