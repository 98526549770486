import React, { useState, useEffect, useRef } from 'react';
import './TagBookModalOne.css'; // Ensure CSS is structured similarly to EditMajorGenreModal.css
import { useSelector, useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { setChosenBook, setChosenFormat, setBooksOnSale, setFormatOptions} from '../../store/taggingSlice';


import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';
import { capitalizeFirstLetterOfEachWord } from '../../utils/capitalize';
import { setChosenAuthor, setRestrictedRetailer, setChosenRetailer, setSearchResults, clearSearchResults } from '../../store/taggingSlice';

const TagBookModal = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const searchResults = useSelector((state) => state.tagging.searchResults);
  const [isSearching, setSearching] = useState(false);
  const chosenBook = useSelector((state) => state.tagging.chosenBook);
  const chosenFormat = useSelector((state) => state.tagging.chosenFormat);
  const formatOptions = useSelector((state) => state.tagging.formatOptions);
  const chosenAuthor = useSelector((state) => state.tagging.chosenAuthor);
  const restrictedRetailer = useSelector((state) => state.tagging.restrictedRetailer);
  const userGeoInfo = useSelector((state) => state.user.userGeoInfo); // Get user location
  const userCountryCode = userGeoInfo?.country_code;
  // const userCountryCode = 'US';
  const [tagBookRetailerDropdownOpen, setTagBookRetailerDropdownOpen] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const tagBookRetailerDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tagBookRetailerDropdownOpen && 
        tagBookRetailerDropdownRef.current && 
        !tagBookRetailerDropdownRef.current.contains(event.target)
      ) {
        setTagBookRetailerDropdownOpen(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [tagBookRetailerDropdownOpen]);

  const handleRetailerSelect = (retailer) => {
    setSelectedRetailer(retailer);
    setTagBookRetailerDropdownOpen(false);
  };

  const handleResetRetailer = () => {
    setSelectedRetailer(null);
    setTagBookRetailerDropdownOpen(false);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getJwtToken = async () => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();
    return jwtToken;

  };

  



  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    })); // Immediately show the next modal
  };


  const handleSearch = async (event) => {
    event.preventDefault();
    setSearching(true);
  
    if (!searchTerm.trim()) {
      setTimeout(() => {
        setSearching(false);
      }, 400);
      return;
    }
  
    const jwtToken = await getJwtToken();
  
    try {
      const response = await apiInstance.get('/tag-search/books-authors-v3', {
        params: {
          query: searchTerm,
          search_type: 'books',
          author: chosenAuthor ? chosenAuthor.author : null,
          available_at_waterstones: userCountryCode === 'GB' ? 'true' : null,
          available_at_bn: userCountryCode !== 'GB' && (!restrictedRetailer || restrictedRetailer === 'BN') ? 'true' : null,
          available_at_bam: userCountryCode !== 'GB' && (!restrictedRetailer || restrictedRetailer === 'BAM') ? 'true' : null
        },
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });
    

      console.log('result', response.data.results);
  
      // Check if response.data.results exists and is an array
      if (Array.isArray(response.data.results)) {
        dispatch(setSearchResults(response.data.results));
      } else {
        console.error('Unexpected response structure:', response.data);
        dispatch(setSearchResults([]));
      }
    } catch (error) {
      console.error('Error during search:', error);
      dispatch(setSearchResults([]));
    } finally {
      setSearching(false);
    }
  };


  const handleSelectBook = (book) => {
    console.log('book', book);
    dispatch(setChosenBook(book));
  };

  const handleClick = () => {
    if (chosenAuthor) {
      // Reset the chosen author
      dispatch(setChosenAuthor(null));
      // Optionally, you can trigger any additional actions needed after resetting
    } else {
      // Open the refine by author modal
      nextModal('REFINE_BY_AUTHOR_MODAL');
    }
  };

  const fetchWaterstonesBookDetails = async () => {
    try {
      const jwtToken = await getJwtToken();
      const response = await apiInstance.get('/retrieve_books/waterstones', {
        params: {
          title: chosenBook.title.toLowerCase(),
          author: chosenBook.author.toLowerCase()
        },
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });
      console.log('Waterstones API Response:', response.data);
  
      dispatch(setBooksOnSale(response.data));
    } catch (error) {
      console.error('Error fetching Waterstones book details:', error);
    }
  };
  

  const handleNextStep = () => {
    let nextStep = 'TAG_BOOK_ONE_AND_HALF';
  
    if (userCountryCode === 'GB') {
      fetchWaterstonesBookDetails();
      dispatch(setChosenRetailer('Waterstones'));
      nextStep = 'TAG_BOOK_TWO';
    } 
  
    dispatch(hideGenericModal());
    dispatch(showGenericModal({ modalType: nextStep }));
  };
  

  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles">Search for a book</h2>

        <form onSubmit={handleSearch}>
            <input
                type="text"
                className="search-input"
                placeholder="Type to search for books..."
                value={searchTerm}
                onChange={handleInputChange}
            />
            <button type="submit" className="infosButtonies-detailss" disabled={isSearching}>
                {isSearching ? 'Searching...' : 'Search'}
            </button>
            <div className="tags-dividers"/>


            <button
                type="button"
                className="infosButton-fullseye"
                onClick={handleClick}
                
            >
                {chosenAuthor ? 'Reset authors' : 'Refine search to an author'}
            </button>
            {userCountryCode !== 'GB' && (
            
              <div className="tagBookRetailerDropdownWrapper">
                <button
                  className="tagBookRetailerDropdownTrigger"
                  onClick={() => setTagBookRetailerDropdownOpen(!tagBookRetailerDropdownOpen)}
                  type="button"
                >
                  {selectedRetailer
                    ? `Restricted to ${selectedRetailer === "Barnes & Noble" ? "B&N" : "BAM"}`
                    : "Restrict search to a retailer"} 
                  <span className="tagBookRetailerDropdownTriangle">&#9662;</span>
                </button>

                {tagBookRetailerDropdownOpen && (
                  <div className="tagBookRetailerDropdown" ref={tagBookRetailerDropdownRef}>
                    <ul>
                      <li onClick={() => handleRetailerSelect("Barnes & Noble")} className={selectedRetailer === "Barnes & Noble" ? "selected" : ""}
                      >
                        Barnes & Noble
                      </li>
                      <li onClick={() => handleRetailerSelect("Books-A-Million")} className={selectedRetailer === "Books-A-Million" ? "selected" : ""}>
                        Books-A-Million
                      </li>
                      {selectedRetailer && (
                        <li onClick={handleResetRetailer}>Reset retailer restriction</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}   

        </form>
        <div className="tags-dividers"/>
        <div className="table-container">
            <table className="results-table">
                <thead>
                    <tr>
                        <th className="header-cell">Title</th>
                        <th className="header-cell">Author</th>
                    </tr>
                </thead>
            </table>
            <div className="scrollable-container">
                <table className="results-table">
                  <tbody>
                     {searchResults.map((book, index) => {
                        const capitalizedTitle = (book.title || book.raw_title) 
                            ? capitalizeFirstLetterOfEachWord(book.title || book.raw_title) 
                            : '';
                        
                        const capitalizedAuthor = book.author 
                            ? capitalizeFirstLetterOfEachWord(book.author) 
                            : '';
                        
                        return (
                          <tr 
                            key={index} 
                            className={`result-item ${chosenBook === book ? 'selected-book' : ''}`} 
                            onClick={() => handleSelectBook(book)}
                          >
                            <td className="result-cell">{capitalizedTitle}</td>
                            <td className="result-cell">{capitalizedAuthor}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
            </div>
        </div>
        <button 
          className="infosButton-details-next" 
          onClick={() => {
            console.log(chosenBook); // Log the chosen book
            handleNextStep();
          }} 
          disabled={!chosenBook}
        >
          Next step &nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;
        </button>
      </div>
    </div>
  );
};

export default TagBookModal;
