import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTab: 'books', // Persist which tab the user was on
  readPosts: [],
  likedPosts: [],
  interestBooks: [],
  readBooks: [],
  ownedBooks: [],
  hasFetchedLibrary: false, // Track if we already fetched the data
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setLibraryData: (state, action) => {
      state.readPosts = action.payload.readPosts;
      state.likedPosts = action.payload.likedPosts;
      state.interestBooks = action.payload.interestBooks;
      state.readBooks = action.payload.readBooks;
      state.ownedBooks = action.payload.ownedBooks;
      state.hasFetchedLibrary = true; // Mark as fetched
    },
    addBookToLibrary: (state, action) => {
      const { book, category } = action.payload;

      // Remove the book from all categories first
      state.readBooks = state.readBooks.filter(b => b.product_link_url !== book.product_link_url);
      state.interestBooks = state.interestBooks.filter(b => b.product_link_url !== book.product_link_url);
      state.ownedBooks = state.ownedBooks.filter(b => b.product_link_url !== book.product_link_url);

      // Add it to the new category
      if (category === "READ") {
        state.readBooks.unshift(book);
      } else if (category === "WANT") {
        state.interestBooks.unshift(book);
      } else if (category === "OWN") {
        state.ownedBooks.unshift(book);
      }
    },
    resetLibraryState: () => initialState, // Resets library on logout
  },
});

export const { 
  setSelectedTab, 
  setLibraryData, 
  addBookToLibrary, 
  resetLibraryState 
} = librarySlice.actions;

export default librarySlice.reducer;
