import React from 'react';
import './CreateAccountBoxHome.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';



const CreateAccountBox = () => {

  const navigate = useNavigate();

  const onCreateAccount = () => {
    navigate('/create-account');
  };


  return (
    <div className="createAccountBox">
      <span className="createText">Don't have a ShorterStories account?&nbsp;</span>
      <button className="submitButtonCreate" onClick={onCreateAccount}>
        Create account
      </button>
    </div>
    
  );
};

export default CreateAccountBox;
