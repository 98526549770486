import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { apiInstance } from '../../utils/axiosInstance';
import Header from '../../components/Header/Header';
import PieceWords from '../../components/PieceWords/PieceWords';
import Footer from '../../components/Footer/Footer';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import { resetPieceData } from '../../store/pieceViewFromFeedSlice';
import './Piece.css';
import { getCurrentUser } from 'aws-amplify/auth';
import BooksInPiece from '../../components/BooksInPiece/BooksInPiece'
import { setUserId } from '../../store/userSlice'; 
import { useLocation } from 'react-router-dom';
import printingPressProfile from '../../assets/images/printingpressprofile.png';
import shareMailImage from '../../assets/images/share-mail-image.png';

async function getJwtToken() {
  const session = await fetchAuthSession();
  return session.tokens.idToken.toString();
}


function Piece()  {
  const dispatch = useDispatch();
  const { pieceHash } = useParams();
  const [liked, setLiked] = useState(false); 
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const feedData = useSelector(state => state.pieceViewFromFeed.pieceData);
  const feedViewHash = useSelector(state => state.pieceViewFromFeed.viewHash);

  const shortieQueue = location.state?.shortieQueue || [];

  const nextShortie = shortieQueue.length > 0 ? shortieQueue[0] : null; // Top of queue
  const newQueue = shortieQueue.slice(1); // Remove first item

  console.log('next', nextShortie);


const goToNextShortie = async () => {
    if (!nextShortie) return;

    // Generate a new hash for tracking this view
    const generateHash = (length) => {
        return crypto.getRandomValues(new Uint8Array(length))
            .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
            .slice(0, length);
    };
    const newPieceViewHash = generateHash(12);

    // Construct view data
    const pieceViewData = {
        piece_view_hash: newPieceViewHash,
        piece_id: nextShortie.piece.piece_id,
        piece_hash: nextShortie.piece.piece_hash,
        referrer_page: `/piece/${pieceHash}`,
        consuming_user: userId,
        referrer_external: '',  // Internal navigation
        piece_writer_name: nextShortie.author_info.name,
        profile_hash: nextShortie.author_info.profile_hash,
        publishing_user: nextShortie.piece.publishing_user,
    };

    try {
        await apiInstance.post('/clicks/pieceview/create', pieceViewData);
        console.log('Shortie view logged successfully', pieceViewData);
    } catch (error) {
        console.error('Error logging shortie view:', error);
    }

    // Reset read tracking so the next piece can log a read correctly
    setHasReachedEnd(false);

    // Navigate to the next shortie
    navigate(`/piece/${nextShortie.piece.piece_hash}`, {
        state: {
            prevRoute: `/piece/${pieceHash}`,
            shortieQueue: newQueue, // Pass down the updated queue
        }
    });
};


  
  const prevRoute = location.state?.prevRoute || null;
  const [pieceViewHash, setpieceViewHash] = useState(feedViewHash || null);
  const [piece, setPiece] = useState(feedData || null);
  useEffect(() => {
    if(feedData) {
      dispatch(resetPieceData());

    }
  }, []);


  const navigate = useNavigate();
  const userId = useSelector(state => state.user.userId);

  const [copied, setCopied] = useState(false);

  const [supportsWebp, setSupportsWebp] = useState(false);
  const [maxScroll, setMaxScroll] = useState(0); 
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [initialAuthChecked, setInitialAuthChecked] = useState(false);

  useEffect(() => {
    const checkAndSetUserId = async () => {
      if (!userId) {
        try {
          setIsLoading(true);
          await dispatch(checkAuthenticationStatus());
          setIsLoading(false);
          const user = await getCurrentUser();
          if (user && user.attributes && user.attributes.sub) {
            dispatch(setUserId(user.attributes.sub));


          }
        } catch (error) {
          console.log('No authenticated user found');
        }
        setInitialAuthChecked(true);
      }

      setInitialAuthChecked(true);
    };

    checkAndSetUserId();
  }, []); 

  

  useEffect(() => {
    const updateMaxScroll = () => {
      const scrolled = window.scrollY;
      const scrollTotal = document.documentElement.scrollHeight - window.innerHeight;
      if (!hasReachedEnd && scrollTotal > 100 && window.scrollY >= scrollTotal - 2) {
        console.log('User has scrolled to the end of the document');
        setHasReachedEnd(true);
      }
    };
  
    window.addEventListener('scroll', updateMaxScroll);
  
    return () => {
      window.removeEventListener('scroll', updateMaxScroll);
    };
  }, [hasReachedEnd]);  // Adding hasReachedEnd as a dependency
  



  function supportersWebp() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }

  useEffect(() => {
    setSupportsWebp(supportersWebp());
  }, []);

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1 < img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};



  

  useEffect(() => {
    // Only if we have a userId and pieceHash
    if (userId && pieceHash) {
      const fetchLikeStatus = async () => {
        try {
          const jwtToken = await getJwtToken();
          if (!jwtToken) {
            console.log('No JWT token, skipping like-status fetch.');
            return;
          }

          const response = await apiInstance.get(
            `/clicks/like-status/${pieceHash}/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );


          if (response.data && typeof response.data.like_status === 'boolean') {
            setLiked(response.data.like_status);
          }


        } catch (error) {
          console.error('Error fetching like status:', error);
        }
      };

      fetchLikeStatus();
    }
  }, [userId, pieceHash]);

  

  useEffect(() => {
    if (!initialAuthChecked) return;

    window.scrollTo(0, 0);
    const fetchPieceData = async () => {
      setIsLoading(true);
      try {
        const response = await apiInstance.get(`/pieces/fetch/v2/${pieceHash}`, {
          params: {
            consumer_id: userId
          }
        });



        if (!piece) {
          const generateHash = (length) => {
            return crypto.getRandomValues(new Uint8Array(length))
              .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
              .slice(0, length);
          };
          const pieceViewHash = generateHash(12);
          setpieceViewHash(pieceViewHash);
          
          const pieceViewData = {
            piece_view_hash: pieceViewHash,
            piece_id: response.data.piece.piece_id,
            piece_hash: response.data.piece.piece_hash,
            referrer_page: prevRoute,
            consuming_user: userId,
            referrer_external: document.referrer,
            piece_writer_name: response.data.author_info.name,
            profile_hash: response.data.author_info.profile_hash,
            publishing_user: response.data.piece.publishing_user,
          };
   
          await apiInstance.post('/clicks/pieceview/create', pieceViewData);
          console.log('Piece view logged successfully', pieceViewData);
        }
        console.log('Piece view not logged successfully');
        setPiece(response.data);

      } catch (error) {
        console.error('Error fetching piece:', error);
      } finally {
        setIsLoading(false);
      }
    };

    window.scrollTo(0, 0);

  
    fetchPieceData();

    setHasReachedEnd(false);

  }, [pieceHash, initialAuthChecked]);

  

  const handleLikeClick = async () => {
    try {
      const jwtToken = await getJwtToken();
      if (!jwtToken) {
        console.log('No JWT token, cannot set like status.');
        return;
      }
  
      // We'll flip the current 'liked' value
      const newLikeStatus = !liked;
      // Optimistic update locally
  
      // Build your request body with all the piece metadata 
      // (similar to how you do for piece reads).
      const requestBody = {
        piece_hash: piece.piece.piece_hash,
        piece_id: piece.piece.piece_id,
        consuming_user: userId,
        profile_hash: piece.author_info.profile_hash,
        like_status: newLikeStatus,
        referrer_page: prevRoute,
        referrer_external: document.referrer,
        piece_writer_name: piece.author_info.name,
        publishing_user: piece.piece.publishing_user,
        serial_first_profile_hash: piece.piece.serial_first_profile_hash,
        serial_part_number: piece.piece.serial_part_number,
        // etc...
      };
  
      // Call your "set-like-status" endpoint
      const response = await apiInstance.post('/clicks/set-like-status', requestBody, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
  
      // The response might contain { old_status, new_status, total_likes, ... }


      setLiked(newLikeStatus); 
  
      // If you want to use the server’s definitive new_status, you can do:
      // setLiked(response.data.new_status);
  
    } catch (error) {
      console.error('Error toggling like status:', error);
      // Optionally revert the optimistic state if it fails
      setLiked(!liked);
    }
  };
  

    // Add another useEffect to react to changes in hasReachedEnd
    useEffect(() => {
      if (hasReachedEnd && piece) {
        console.log('hasReachedEnd is now true');  // This logs when hasReachedEnd is true



    
        // Prepare the data using the existing pieceViewHash
        const pieceReadData = {
          piece_view_hash: pieceViewHash,  // Reusing the hash generated for the view
          piece_id: piece.piece.piece_id,
          piece_hash: piece.piece.piece_hash,
          piece_title: piece.piece.title, // New field
          piece_image_jpeg: piece.piece.piece_image_jpeg, // New field
          piece_image_webp: piece.piece.piece_image_webp, // New field
          serial_first_profile_hash: piece.piece.serial_first_profile_hash, // New field
          serial_part_number: piece.piece.serial_part_number, // New field
          referrer_page: prevRoute,
          consuming_user: userId,
          referrer_external: document.referrer,
          piece_writer_name: piece.author_info.name,
          profile_hash: piece.author_info.profile_hash,
          publishing_user: piece.piece.publishing_user,
        };


    
        // Post the piece read data to the server
        apiInstance.post('/clicks/pieceread/create', pieceReadData)
          .then(response => console.log('Piece read logged successfully', response))
          .catch(error => console.error('Error logging piece read:', error));
      }
    }, [hasReachedEnd, piece, prevRoute, userId, pieceViewHash]);  // Ensure pieceViewHash is included in the dependencies
    
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!piece) {
    return <div>Loading...</div>;
  }



  const getLengthCategory = (wordCount) => {

    if (wordCount <= 250) return 'Bite Sized';
    if (wordCount <= 1500) return 'Flash';
    if (wordCount <= 5000) return 'Shorter Story';
    return 'Short Story';
  };

  const handleAuthorClick = (piece) => {
    navigate(`/writer/${piece.author_info.profile_hash}`, { state: { authorInfo: piece.author_info } });
  };

  


  const goToNextPiece = () => {
  window.scrollTo(0, 0);


  const nextPieceHash = piece.piece.next_serial_hash;
  const currentPieceHash = pieceHash;

  navigate(`/piece/${nextPieceHash}`, { 
    state: { 
      prevRoute: `/piece/${currentPieceHash}`,
      pieceData: null  
    } 
  });


  apiInstance.get(`/pieces/fetch/${nextPieceHash}`, {
    params: {
      consumer_id: userId
    }
  })
    .then(response => {
      setPiece(response.data);

      console.log('Next Piece:', response.data);

      // Generate the hash
      const generateHash = (length) => {
        return crypto.getRandomValues(new Uint8Array(length))
          .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
          .slice(0, length);
      };
      const newPieceViewHash = generateHash(12);
      setpieceViewHash(newPieceViewHash);

      const pieceViewData = {
        piece_view_hash: newPieceViewHash,
        piece_id: response.data.piece.piece_id,
        piece_hash: response.data.piece.piece_hash,
        referrer_page: `/piece/${currentPieceHash}`,
        consuming_user: userId,
        referrer_external: '',  // It's internal navigation
        piece_writer_name: response.data.author_info.name,
        profile_hash: response.data.author_info.profile_hash,
        publishing_user: response.data.piece.publishing_user,
      };

      apiInstance.post('/clicks/pieceview/create', pieceViewData)
      .then(res => console.log('Next piece view logged successfully', res))
      .catch(error => console.error('Error logging next piece view:', error));

    // Reset scroll position and hasReachedEnd
    window.scrollTo(0, 0);
    setHasReachedEnd(false);

    
  }).catch(error => {
    console.error('Error fetching next piece:', error);
  });


  };

  const goToPreviousPiece = () => {
    window.scrollTo(0, 0);
  
    const previousPieceHash = piece.piece.previous_serial_hash;
    const currentPieceHash = pieceHash;
  
    navigate(`/piece/${previousPieceHash}`, { 
      state: { 
        prevRoute: `/piece/${currentPieceHash}`,
        pieceData: null  
      } 
    });

  
    apiInstance.get(`/pieces/fetch/${previousPieceHash}`, {
      params: {
        consumer_id: userId
      }
    })
      .then(response => {
        setPiece(response.data);
  
        console.log('Previous Piece:', response.data);
  
        // Generate the hash
        const generateHash = (length) => {
          return crypto.getRandomValues(new Uint8Array(length))
            .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
            .slice(0, length);
        };
        const newPieceViewHash = generateHash(12);
        setpieceViewHash(newPieceViewHash);
  
        const pieceViewData = {
          piece_view_hash: newPieceViewHash,
          piece_id: response.data.piece.piece_id,
          piece_hash: response.data.piece.piece_hash,
          referrer_page: `/piece/${currentPieceHash}`,
          consuming_user: userId,
          referrer_external: '',  // It's internal navigation
          piece_writer_name: response.data.author_info.name,
          profile_hash: response.data.author_info.profile_hash,
          publishing_user: response.data.piece.publishing_user,
        };
  
        apiInstance.post('/clicks/pieceview/create', pieceViewData)
        .then(res => console.log('Previous piece view logged successfully', res))
        .catch(error => console.error('Error logging previous piece view:', error));
  
      // Reset scroll position and hasReachedEnd
      window.scrollTo(0, 0);
      setHasReachedEnd(false);
    }).catch(error => {
      console.error('Error fetching previous piece:', error);
    });
  
    window.scrollTo(0, 0);
    setHasReachedEnd(false);
  };

  function splitContentAtBreakpoint(htmlContent, breakpoint) {
    if (breakpoint === null) {
      return [htmlContent, null]; // No split if no breakpoint
    }
  
    return [
      htmlContent.slice(0, breakpoint), // First half of the HTML
      htmlContent.slice(breakpoint)    // Second half of the HTML
    ];
  }
  

  function findAdBreakpoint(htmlContent, searchRadius) {
    const midpoint = Math.floor(htmlContent.length / 2); // Approximate midpoint
    const lowerBound = Math.max(0, midpoint - searchRadius);
    const upperBound = Math.min(htmlContent.length, midpoint + searchRadius);
  
    // Search forward within the range
    for (let i = midpoint; i <= upperBound; i++) {
      if (htmlContent.slice(i, i + 4) === '</p>') {
        return i + 4; // Include the closing </p>
      }
    }
  
    // Search backward within the range
    for (let i = midpoint; i >= lowerBound; i--) {
      if (htmlContent.slice(i, i + 4) === '</p>') {
        return i + 4; // Include the closing </p>
      }
    }
  
    // No valid breakpoint found
    return null;
  }
  
  const searchRadius = 500; // Adjustable range
  const adBreakpoint = findAdBreakpoint(piece.piece.piece_content, searchRadius);
  const [contentBeforeAd, contentAfterAd] = splitContentAtBreakpoint(piece.piece.piece_content, adBreakpoint);




  return (
    <div className="piecepage">
      <Header />
      <div className="contents-container-piece"> {/* New container for content */}
        <div className="piece-author-info">
          <div className='piece-author-only' onClick={() => handleAuthorClick(piece)} style={{ cursor: 'pointer' }}>
            <img src={piece.author_info.profile_image_jpeg||piece.author_info.vertical_image_jpeg||printingPressProfile} alt="Author" className="piece-author-photo" />
            <span className="piece-author-name">{piece.author_info.name}</span>
          </div>
          {/* <span className="preview-length-indicator">{getLengthCategory(piece.piece.piece_length)}</span> */}
          
          <span className="genre-style-text-piece">
              {piece.piece.piece_genre}
          </span>
        </div>
        <div className="piece-image-container" key={piece.piece.piece_hash}>
        {supportsWebp && piece.piece.piece_image_webp ? (
          <img src={piece.piece.piece_image_webp} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
        ) : piece.piece.piece_image_jpeg ? (
          <img src={piece.piece.piece_image_jpeg} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
        ) : (
          piece.piece.image && <img src={piece.piece.image} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
        )}
        </div>
        <div className="genre-style-container-piece">
          <span className="genre-style-text-piece">{piece.piece.piece_length} words</span>
          <span className="genre-style-text-piece">
            Style: <span className="italic-text">{piece.piece.piece_style}</span>
          </span>
        </div>
        <div className="divider-piece"></div>
        {piece.links && piece.links.length > 0 && (
          <>
            <div className="promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={piece.links} prevRoute={prevRoute} piece={piece.piece} author_info={piece.author_info} pieceViewHash={pieceViewHash}/>
            
            <div className="divider-piece"></div>
          </>
        )}
        <div className="piecepage-words-piece">
          <PieceWords className="piecewords"
            title={piece.piece.title}
            authorName={piece.piece.author_name}
            content={piece.piece.piece_content}
            isSerial={piece.piece.is_serial}
            serialPartNumber={piece.piece.serial_part_number}
            previousSerialHash={piece.piece.previous_serial_hash}
            nextSerialHash={piece.piece.next_serial_hash}
            onNextClick={goToNextPiece}
            onPreviousClick={goToPreviousPiece}
            booksInPiece={{
              feedItems: piece.links,
              prevRoute,
              piece: piece.piece,
              author_info: piece.author_info,
              pieceViewHash,
            }}
          
          />
        </div>
        {piece.piece.is_serial && piece.piece.next_serial_hash && piece.piece.piece_length > 250 && (
          <div className="serial-navigation">
            <div className="serial-nav-link next" onClick={goToNextPiece}> 
              <span>
                <span className="main-text">Next piece</span>
                <span className="arrow">&#8594;</span>
                <br />
                <span className="sub-text">in series</span>
              </span>
            </div>
          </div>
        )}
        {nextShortie && (
          <div className="serial-navigation">
            <div className="serial-nav-link next" onClick={goToNextShortie}> 
              <span>
                <span className="main-text">Take your luck with the next {nextShortie.piece.piece_length}-word shortie</span>
                <span className="arrow">&#8594;</span>
              </span>
            </div>
          </div>
        )}

        <div className="divider-piece"></div>

        <div className="piece-like-section">
          {userId ? (
            <button
              className={`piece-action-button ${
                liked ? 'piece-action-button-liked' : 'piece-action-button-unchosen'
              }`}
              onClick={handleLikeClick}
            >
              {liked ? 'I liked this piece' : 'I liked this piece'}
            </button>
          ) : (
            <div className="piece-create-account-section">

              <p className="piece-create-account-text">
                Did you like this piece? An account allows you to save pieces you like, build a library and get personalized recommendations
              </p>
              <button
                className="piece-create-account-button"
                onClick={() => navigate('/login')}
              >
                Create account /  Log in
              </button>
            </div>
          )}
        </div>
        
        {piece.links && piece.links.length > 0 && (
          <>
            <div className="divider-piece"></div>
            <div className="promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={piece.links} prevRoute={prevRoute} piece={piece.piece} author_info={piece.author_info} pieceViewHash={pieceViewHash}/>
            
            <div className="divider-piece"></div>
          </>
        )}
        <div className="piece-share-section">
          <button
            className={`piece-action-button ${
              liked ? 'piece-action-button-bold' : ''
            }`}
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setCopied(true); // Trigger copied state
              setTimeout(() => setCopied(false), 1500); // Reset after 0.5 seconds
            }}
          >
            
            Share piece 
            <img
              src={shareMailImage} /* Replace with actual icon path */
              alt="Share Icon"
              className="piece-share-icon"
            />
          </button>
          {copied && <div className="piece-copied-tooltip">Copied to clipboard!</div>}
        </div>
        <div className="divider-piece"></div>
        <div className="piece-author-link-section">
          <div
            className="piece-author-link"
            onClick={() => handleAuthorClick(piece)}
            style={{ cursor: 'pointer', textAlign: 'center', margin: '20px 0' }}
          >
            <img
              src={
                piece.author_info.profile_image_jpeg ||
                piece.author_info.vertical_image_jpeg ||
                printingPressProfile
              }
              alt="Author"
              className="piece-author-link-photo"
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                objectFit: 'cover',
                margin: '0 auto',
              }}
            />
            <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 'normal' }}>
              Visit {piece.author_info.name}'s profile
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Piece;