import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiInstance } from '../../utils/axiosInstance';
import Header from '../../components/Header/Header';
import ProfileFeed from '../../components/ProfileFeed/ProfileFeed';
import AuthorSummary from '../../components/AuthorSummary/AuthorSummary';
import './ProfilePage.css';
import { checkAuthenticationStatus } from '../../store/userSlice';

const ProfilePage = ({ authorInfo }) => {
  const dispatch = useDispatch();
  const { profileHash } = useParams(); 
  const [authorProfile, setAuthorProfile] = useState(authorInfo || null);
  const [piecesShorties, setPiecesShorties] = useState([]);
  const [piecesLongform, setPiecesLongform] = useState([]);
  const [topBooks, setTopBooks] = useState([]);
  
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userId = useSelector(state => state.user.userId);
  
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isFeedsLoading, setIsFeedsLoading] = useState(true);
  const [feedType, setFeedType] = useState('shorties');

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(checkAuthenticationStatus());
      setIsAuthChecked(true);
    };
    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthChecked || !profileHash) return;

    const fetchAuthorProfile = async () => {
      try {
        const response = await apiInstance.get(`/platformAuthors/summary/fetch?profile_hash=${profileHash}`);
        setAuthorProfile(response.data);
      } catch (error) {
        console.error('Error fetching the author profile:', error);
      }
    };

    const fetchTopBooks = async () => {
      try {
        const response = await apiInstance.get(`/links/top_books_profile?profile_hash=${profileHash}`);
        setTopBooks(response.data.top_books);
      } catch (error) {
        console.error('Failed to fetch top books:', error?.response?.data);
      }
    };

    const fetchProfileFeeds = async () => {
      try {
        setIsFeedsLoading(true);
        let baseUrl = `/pieces/profilefeed/fetch/v2?profile_hash=${profileHash}`;
        if (isAuthenticated && userId) {
          baseUrl += `&consumer_id=${userId}`;
        }

        const [shortiesRes, longformRes] = await Promise.all([
          apiInstance.get(`${baseUrl}&feed_type=shorties`),
          apiInstance.get(`${baseUrl}&feed_type=longform`),
        ]);

        setPiecesShorties(shortiesRes.data.feed_data || []);
        setPiecesLongform(longformRes.data.feed_data || []);

        // Choose first available feed
        if (shortiesRes.data.feed_data.length === 0 && longformRes.data.feed_data.length > 0) {
          setFeedType('longform');
        }

        setIsFeedsLoading(false);
      } catch (error) {
        console.error('Error fetching profile feeds:', error);
        setIsFeedsLoading(false);
      }
    };

    fetchAuthorProfile();
    fetchTopBooks();
    fetchProfileFeeds();
  }, [isAuthChecked, profileHash, isAuthenticated, userId]);

  const activePieces = feedType === 'shorties' ? piecesShorties : piecesLongform;
  const hasShorties = piecesShorties.length > 0;
  const hasLongform = piecesLongform.length > 0;

  return (
    <div className="profilePage">
      <Header />

      {!authorProfile && isFeedsLoading ? (
        <div className="profilePage-loading">Loading profile...</div>
      ) : (
        <>
          {authorProfile && <AuthorSummary summary={authorProfile} topBooks={topBooks} />}
          
          {authorProfile && isFeedsLoading && (
            <div className="profilePage-loading">Feeds loading...</div>
          )}

          {(hasShorties || hasLongform) && (
            <>
              <div className="profilePage-mode-toggle-container">
                <button
                  className={`profilePage-pill-button ${feedType === 'shorties' ? 'active' : ''} ${!hasShorties ? 'disabled' : ''}`}
                  onClick={() => hasShorties && setFeedType('shorties')}
                >
                  Shorties
                </button>
                <button
                  className={`profilePage-pill-button ${feedType === 'longform' ? 'active' : ''} ${!hasLongform ? 'disabled' : ''}`}
                  onClick={() => hasLongform && setFeedType('longform')}
                >
                  Longform
                </button>
              </div>
              <hr className="profilePage-divider" />
            </>
          )}

          {activePieces.length > 0 && <ProfileFeed pieces={activePieces} authorProfile={authorProfile} feedType={feedType}  />}
        </>
      )}
    </div>
  );
};

export default ProfilePage;
