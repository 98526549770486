import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeDesign.css';
import Opening from '../../assets/images/opening-scene-latest.png';
import BookWorld from '../../assets/images/BookWorld.png';

function AboutWords() {
  const navigate = useNavigate();

  const handleInfo = (route) => {
    console.log(`Navigating to ${route}`);
    window.scrollTo(0, 0);
    navigate(route);
  };

  return (
    <div className="about-words">
      <h3>Life is short — read Shorter Stories</h3>
      <p>
      The platform you didn’t know you always wanted: part bookstore, part library, part social reading experience. 
      </p>
      <p>
      Enjoy shorties (quick reads), discover serialized longform works and get lost, and before you know it, you've found your new favorite bookstore.
      </p>
      <button className="cta-button" onClick={() => handleInfo("/login")}>
        Create your account / log in
      </button>

      <div className="image-birdie-container">
          <img src={Opening} alt="Opening" className="centered-birdie-imagess" />
      </div>
      <hr className="subtle-line-aboutwords" />


      {/* Encourage Account Creation Section */}

      <div className="account-creation-section">
        
        <h3>Take a look around </h3>
        <p>
          At the link below, you can explore a pared back version of the reader’s experience to get a feel for the stories, books, and connections ShorterStories offers.
        </p>
        <button className="cta-button" onClick={() => handleInfo("/readers")}>
          Enter the reader's site 
        </button>

        <div className="image-birdie-container">
          <img src={BookWorld} alt="BookWorld" className="centered-birdie-image" />
        </div>

      </div>

      <hr className="subtle-line-aboutwords" />
      <p>
        If you’ve got questions, click the link below that feels right and learn what it means for you:


      </p>
      <ul className="about-list">
        <li><span className="clickable-link" onClick={() => handleInfo("/about/readers")}>Reader or bookshopper</span></li>
        <li><span className="clickable-link" onClick={() => handleInfo("/about/writers")}>Writer</span></li>   
        <li><span className="clickable-link" onClick={() => handleInfo("/about/industry")}>Bookseller or anyone in the biz</span></li>
      </ul>

    </div>
  );
}

export default AboutWords;