import React, { useState, useEffect } from 'react';
import './AuthorRefinationModalEdit.css';
import { useSelector, useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { setChosenAuthor, setSearchResults, clearSearchResults } from '../../store/taggingEditSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';

const RefineByAuthorModalEdit = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const chosenAuthor = useSelector((state) => state.taggingEdit.chosenAuthor);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getJwtToken = async () => {
    const session = await fetchAuthSession(); 
    return session.tokens.idToken.toString();
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setSearching(true);

    if (!searchTerm.trim()) {
      setTimeout(() => setSearching(false), 400);
      return;
    }

    const jwtToken = await getJwtToken();

    try {
      const response = await apiInstance.get('tag-search/books-authors-v2', {
        params: {
          query: searchTerm,
          search_type: 'authors'
        },
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });

      if (Array.isArray(response.data.results)) {
        setSearchResults(response.data.results);
        console.log('Search Results:', response.data.results);
      } else {
        console.error('Unexpected response structure:', response.data);
        dispatch(setSearchResults([]));
      }
    } catch (error) {
      console.error('Error during search:', error);
      dispatch(setSearchResults([]));
    } finally {
      setSearching(false);
    }
  };

  const handleSelectAuthor = (author) => {
    dispatch(setChosenAuthor(author));
  };

  const handleCancel = () => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK_EDIT',  // ✅ Updated to Editing Flow
    }));
  };

  const handleUpdate = () => {
    console.log(chosenAuthor);
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK_EDIT',  // ✅ Updated to Editing Flow
    }));
  };

  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles">Refine by author:</h2>
        <div className="forbreaks-hopes">
          <label className="sectionTitle">Search for an author:</label>
        </div>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            className="search-input"
            placeholder="Type to search for authors..."
            value={searchTerm}
            onChange={handleInputChange}
          />
          <button type="submit" className="infosButtonies-detailss" disabled={isSearching}>
            {isSearching ? 'Searching...' : 'Search'}
          </button>
        </form>
        <div className="tags-dividers"/>
        <div className="table-container">
          <table className="results-table">
            <thead>
              <tr>
                <th className="header-cell">Author</th>
              </tr>
            </thead>
          </table>
          <div className="scrollable-container">
            <table className="results-table">
              <tbody>
                {searchResults.map((authorObj, index) => {
                  const authorName = authorObj.author;
                  return (
                    <tr 
                      key={index} 
                      className={`result-item ${chosenAuthor === authorObj ? 'selected-book' : ''}`} 
                      onClick={() => handleSelectAuthor(authorObj)}
                    >
                      <td className="result-cell">{authorName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <button 
          className="infosButton-details-next" 
          onClick={handleUpdate}
          disabled={!chosenAuthor}
        >
          Update
        </button>
        <button 
          className="infosButton-cancel" 
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RefineByAuthorModalEdit;
