import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProfileFeed.css';
import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import usePreviousRoute from '../../hooks/usePreviousRoute';
import { apiInstance } from '../../utils/axiosInstance';
import closeIcon from '../../assets/images/circle-xmark-regular.svg'; 
import { useDispatch } from 'react-redux';
import { initializeEditingState } from '../../store/editingSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import pancakeImage from '../../assets/images/menufour.jpeg';
import heart from '../../assets/images/heart.svg';
import reads from '../../assets/images/read-book-icon.svg';
import { useRef } from 'react';
import BooksInPiece from '../../components/BooksInPiece/BooksInPiece'


const ProfileFeed = ({ pieces: initialPieces, authorProfile, feedType }) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const dispatch = useDispatch();
  const prevRoute = usePreviousRoute();
  const userId = useSelector(state => state.user.userId);
  const [pieces, setPieces] = useState(initialPieces);

  useEffect(() => {
    console.log("Updating pieces in ProfileFeed:", initialPieces);
    setPieces(initialPieces);
  }, [initialPieces]);

  const menuIconRef = useRef(null);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedPieceHash, setSelectedPieceHash] = useState(null);
  const [clickedInside, setClickedInside] = useState(false);

  const handleMenuOpen = (event, pieceHash) => {
    event.preventDefault();
    setClickedInside(true); // Mark this as an intentional click
  
    if (menuVisible && selectedPieceHash === pieceHash) {
      handleMenuClose();
    } else {
      const rect = event.target.getBoundingClientRect();
      setMenuVisible(true);
      setMenuPosition({
        x: rect.right - 10,
        y: rect.bottom + window.scrollY,
      });
      setSelectedPieceHash(pieceHash);
    }
  };
  
  
  const handleMenuClose = () => {
    setMenuVisible(false);
    setSelectedPieceHash(null);
  };

  const transformLinks = (incomingLinks) => {
    console.log('incomingLinks', incomingLinks);
    return incomingLinks.map(link => ({
      
      full_title: link.book_title || "", // Converts 'book_title' to 'full_title'
      author: link.book_author_name || "", // Converts 'book_author_name' to 'author'
      click_url: link.affiliate_url || "", // Converts 'affiliate_url' to 'click_url'
      link: link.product_link_url || "", // Converts 'product_link_url' to 'link'
      image_link: link.book_image_url || "", // Converts 'book_image_url' to 'image_link'
      reason: link.reason_why || "", // Converts 'reason_why' to 'reason'
      publisher: link.publisher || "", // Direct match
      raw_description: link.raw_description || "", // Direct match
      role: link.cj_role || "", // Converts 'cj_role' to 'role'
      external_catalogue_id: link.external_catalogue_id || "", // Direct match
      external_product_id: link.external_product_id || "", // Direct match
      external_link_id: link.external_link_id || "", // Direct match
      external_retailer_id: link.external_retailer_id || "", // Direct match
      affiliate_network_name: link.affiliate_network_name || "", // Direct match
      affiliate_network_id: link.affiliate_id || "", 
      retailer_name: link.retailer_name || "", // Direct match
      retailer_id: link.retailer_id || "", // Direct match
      format: link.format || "", // Direct match


    }));
  };
  
  



  const handleEditPiece = () => {
    handleMenuClose();
  
    // Find the selected piece data
    const selectedPiece = pieces.find(
      (piece) => piece.piece.piece_hash === selectedPieceHash
    );
  
    if (selectedPiece) {
      // Dispatch the correct structure to the reducer
      dispatch(
        initializeEditingState({
          currentStage: 'coverart',
          currentPhoto: selectedPiece.piece.piece_image_webp || selectedPiece.piece.piece_image_jpeg,
          title: selectedPiece.piece.title,
          subtitle: selectedPiece.piece.subtitle || '',
          description: selectedPiece.piece.description,
          piece: selectedPiece.piece.piece_content,
          taggedBooks: transformLinks(selectedPiece.links || []),
          genre: selectedPiece.piece.piece_genre,
          style: selectedPiece.piece.piece_style,
          serialDetails: {
            isSerial: selectedPiece.piece.is_serial,
            firstProfileHash: selectedPiece.piece.serial_first_profile_hash,
            partNumber: selectedPiece.piece.serial_part_number,
            previousSerialHash: selectedPiece.piece.previous_serial_hash,
          },
          hasChanges: false,
          pieceHash: selectedPiece.piece.piece_hash, // Adding this explicitly
        })
      );
  
      // Navigate to the editing screen
      navigate(`/writers/edit/coverart`);
    } else {
      console.error('Selected piece not found.');
    }
  };
  

  const handleDeletePiece = async (pieceHash) => {
    if(window.confirm("Are you sure you want to delete this piece? This action cannot be undone.")) {
      console.log("Attempting to delete piece:", pieceHash);
  
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

  
      const deleteUrl = `pieces/delete_piece/${pieceHash}`;
  
      const config = {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        }
      };
  
      apiInstance.patch(deleteUrl, {}, config)
        .then(response => {
          console.log('Piece marked as deleted successfully:', response.data);
          const updatedPieces = pieces.filter(piece => piece.piece.piece_hash !== pieceHash);
          setPieces(updatedPieces);
          console.log('Piece marked',pieces);
        })
        .catch(error => {
          console.error('Error marking piece as deleted:', error);
        });
    }
  };
  


  const [supportsWebp, setSupportsWebp] = useState(false);

  function supportersWebp() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }

  useEffect(() => {
    setSupportsWebp(supportersWebp());
  }, []);

  


  const goToPiecePage = async (item) => {
    const generateHash = (length) => {
      return crypto.getRandomValues(new Uint8Array(length))
        .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
        .slice(0, length);
    };

    const pieceViewHash = generateHash(12);

    const pieceViewData = {
      piece_view_hash: pieceViewHash,
      piece_id: item.piece.piece_id,
      piece_hash: item.piece.piece_hash,
      referrer_page: prevRoute,
      consuming_user: userId,
      referrer_external: document.referrer,
      piece_writer_name: item.author_info.name,
      profile_hash: item.author_info.profile_hash,
      publishing_user: item.piece.publishing_user,
    };

    apiInstance.post('/clicks/pieceview/create', pieceViewData)
      .then(response => console.log('Piece view logged successfully (profile)', response))
      .catch(error => console.error('Error logging piece view:', error));

    // **Only do this for shorties**
    if (feedType === 'shorties') {
      const currentIndex = pieces.findIndex(p => p.piece.piece_hash === item.piece.piece_hash);
      const remainingQueue = currentIndex !== -1 ? pieces.slice(currentIndex + 1) : [];

      navigate(`/piece/${item.piece.piece_hash}`, {
        state: {
          prevRoute: prevRoute,
          shortieQueue: remainingQueue, // Send remaining shorties as a queue
        }
      });
    } else {
      navigate(`/piece/${item.piece.piece_hash}`, {
        state: { prevRoute: prevRoute }
      });
    }
  };

  const handleAuthorClick = (item) => {

    window.scrollTo(0, 0);

     
  };

  const getLengthCategory = (length) => {
    if (length <= 250) return 'Bite Sized';
    if (length <= 1500) return 'Flash';
    if (length <= 5000) return 'Shorter Story';
    return 'Short Story';
  };

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1 < img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

const numberToWord = (num) => {
  const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  return num >= 1 && num <= 9 ? words[num - 1] : num.toString();
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      menuVisible &&
      !event.target.closest('.profileFeed-menu') && // Check if click is outside the menu
      !event.target.closest('.profileFeed-deleteIcon') // Check if click is outside the pancakes icon
    ) {
      handleMenuClose();
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [menuVisible]);

const goToSeriesPage = (item) => {
  window.scrollTo(0, 0);
  navigate(`/serial/${item.piece.serial_first_profile_hash}`);
};





return (
  <div className="profileFeed-contentContainer">
    {pieces.map((item, index) => (
      <div key={index} className="profileFeed-feedItem">
        <div className="profileFeed-authorAndLengthInfo">
          <div className="profileFeed-authorInfo" onClick={() => handleAuthorClick(item)} style={{ cursor: 'pointer' }}>
            <picture>
              {authorProfile?.profile_image_webp && (
                <source srcSet={authorProfile.profile_image_webp} type="image/webp" />
              )}
              {authorProfile?.profile_image_jpeg && (
                <source srcSet={authorProfile.profile_image_jpeg} type="image/jpeg" />
              )}
              <img 
                src={authorProfile?.vertical_image_webp || authorProfile?.vertical_image_jpeg || printingPressProfile} 
                alt={item.piece?.name || 'Unknown Piece'} 
                className="profileFeed-authorPhoto" 
              />
            </picture>
            <span className="profileFeed-authorName">{authorProfile?.name || 'Unknown Author'}</span>
          </div>

          {location.pathname === "/writers/profile" && userId === item.piece?.publishing_user ? (
            <>
              <img 
                src={pancakeImage} 
                alt="Delete"
                className="profileFeed-deleteIcon"
                onClick={(e) => handleMenuOpen(e, item.piece?.piece_hash)}
              />
              {menuVisible && selectedPieceHash === item.piece?.piece_hash && (
                <div 
                  className="profileFeed-menu" 
                  style={{ top: menuPosition.y, left: menuPosition.x }}
                >
                  <ul>
                    <li onClick={() => handleEditPiece(selectedPieceHash)}>Edit</li>
                    <li onClick={() => handleDeletePiece(selectedPieceHash)}>Delete</li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <span className="profileFeed-genre-style-text">{item.piece?.piece_genre || 'No Genre'}</span>
          )}
        </div>

        {supportsWebp && item.piece?.piece_image_webp ? (
          <img src={item.piece.piece_image_webp} alt="Piece" className="profileFeed-pieceImage" onLoad={handleImageLoad} onClick={() => goToPiecePage(item)} />
        ) : item.piece?.piece_image_jpeg ? (
          <img src={item.piece.piece_image_jpeg} alt="Piece" className="profileFeed-pieceImage" onLoad={handleImageLoad} onClick={() => goToPiecePage(item)} />
        ) : (
          item.piece?.image && <img src={item.piece.image} alt="Piece" className="profileFeed-pieceImage" onLoad={handleImageLoad} onClick={() => goToPiecePage(item)} />
        )}

        <div className="profileFeed-pieceInfo">
          <div className="profileFeed-title-and-button-container">
            <h3 className="clickable" onClick={() => item.piece?.is_serial ? goToSeriesPage(item) : goToPiecePage(item)}>
              {item.piece?.title || 'Untitled'}
              {item.piece?.is_serial && item.piece.serial_total_parts > 1 && (
                <span className="profileFeed-serial-part">
                  {" - a story in " + numberToWord(item.piece.serial_total_parts) + " parts"}
                </span>
              )}
            </h3>

            <button
              className="profileFeed-read-piece-button"
              onClick={() => item.piece?.is_serial ? goToSeriesPage(item) : goToPiecePage(item)}
            >
              {item.piece?.is_serial ? "View series" : "Read"}
            </button>
          </div>

          <p>{item.piece?.description || 'No description available'}</p>
          <div className="profileFeed-genre-style-container">
            <span className="profileFeed-genre-style-text">
              {item.piece?.piece_length || 0} words - {item.stats?.total_likes || 0} <img src={heart} alt="Likes" className="iconista" /> - {item.stats?.total_reads || 0} <img src={reads} alt="Total Reads" className="iconista" />
            </span>
            <span className="profileFeed-genre-style-text">Style: <span className="italic-text">{item.piece?.piece_style || 'No style'}</span></span>
          </div>
        </div>

        {item.links?.length > 0 && (
          <>
            <hr className="profileFeed-divider" />
            <div className="promoted-books-text">Promoted books</div>
            <BooksInPiece
              feedItems={item.links}
              prevRoute={prevRoute}
              piece={item.piece}
              author_info={item.author_info}
              pieceViewHash={"someHashOrVariable"} 
              clicketyType={"PROFILEFEED"} 
            />
          </>
        )}
        <hr className="profileFeed-divider" />
      </div>
    ))}
  </div>
);
};

export default ProfileFeed;
