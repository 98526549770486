import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chosenBook: null,
  chosenFormat: null,
  chosenEdition: null,
  taggedBooks: [],
  formatOptions: [],
  booksOnSale: {books_on_sale:[]},
  bnBooksOnSale: [], // New state for BN books on sale
  selectedBookOnSale: null,
  selectedBookOnSaleBN: null,
  chosenRetailer: null,
  chosenAuthor: null, // New state for chosen author
  restrictedRetailer: null, 
  searchResults: [],
}; 

const taggingSlice = createSlice({
  name: 'tagging',
  initialState,
  reducers: {
    setChosenBook(state, action) {
      state.chosenBook = action.payload;
    },
    setChosenFormat(state, action) {
      state.chosenFormat = action.payload;
    },
    setChosenEdition(state, action) {
      state.chosenEdition = action.payload;
    },
    setChosenRetailer(state, action) {
      state.chosenRetailer = action.payload;
    },
    setChosenAuthor(state, action) { // New action for chosen author
      state.chosenAuthor = action.payload;
    },
    setRestrictedRetailer(state, action) { // New action for setting restricted retailer
      state.restrictedRetailer = action.payload;
    },
    clearSelections(state) {
      state.chosenBook = null;
      state.chosenFormat = null;
      state.chosenEdition = null;
      state.chosenAuthor = null; // Clear chosen author
      state.taggedBooks = [];
      state.formatOptions = [];
      state.booksOnSale = {books_on_sale:[]};
      state.bnBooksOnSale = []; // Clear BN books on sale
      state.selectedBookOnSale = null;
      state.selectedBookOnSaleBN = null;
      state.restrictedRetailer = null;
      state.searchResults = [];
    },
    addTaggedBook(state, action) {
      state.taggedBooks.push(action.payload);
    },
    setFormatOptions(state, action) {
      state.formatOptions = action.payload;
    },
    setBooksOnSale(state, action) {
      state.booksOnSale = action.payload;
    },
    setBnBooksOnSale(state, action) { // New action to set BN books on sale
      state.bnBooksOnSale = action.payload;
    },
    clearBnBooksOnSale(state) { // New action to clear BN books on sale
      state.bnBooksOnSale = [];
    },
    setSelectedBookOnSale(state, action) {
      state.selectedBookOnSale = action.payload;
    },
    setSelectedBookOnSaleBN(state, action) {
      state.selectedBookOnSaleBN = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
  },
});

export const {
  setChosenBook,
  setChosenFormat,
  setChosenEdition,
  setChosenAuthor, // Export new action
  clearSelections,
  setRestrictedRetailer,
  addTaggedBook,
  setFormatOptions,
  setBooksOnSale,
  setBnBooksOnSale, // Export new action
  clearBnBooksOnSale, // Export new action
  setSelectedBookOnSale,
  setSelectedBookOnSaleBN,
  setChosenRetailer,
  setSearchResults,
  clearSearchResults,
} = taggingSlice.actions;

export default taggingSlice.reducer;
