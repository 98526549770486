import React, { useState, useEffect } from 'react';
import { debounce } from "lodash";
import Header from '../../components/Header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { useRef } from 'react';


import './Find.css';
import { apiInstance } from '../../utils/axiosInstance';
import AuthorSummarySearch from '../../components/AuthorSummarySearch/AuthorSummarySearch';
import { Helmet } from 'react-helmet';
import BookCardSearch from '../../components/BookCardSearch/BookCardSearch';
import {
  setQuery,
  setSelectedTab,
  setResults,
  setResponseMessage,
  setRandomFetched,
  setLoading
} from '../../store/findSlice';

function Find() {
  const dispatch = useDispatch();
  const {
    writersQuery,
    booksQuery,
    selectedTab,
    results = { writers: [], books: [] },
    responseMessage,
    randomFetched,
    loading
  } = useSelector(state => state.find);

  // 🔥 Get the correct query based on tab
  const currentQuery = selectedTab === 'writers' ? (writersQuery || '') : (booksQuery || '');

  const [localQuery, setLocalQuery] = useState(currentQuery);  // 🔥 Local state for UI input

  const [searchTimestamp, setSearchTimestamp] = useState(null); // 🔥 Track search time
  const [randomFetchTimestamp, setRandomFetchTimestamp] = useState(null); 

  const isFetchingWriters = useRef(false);

  

// Sync `localQuery` with `currentQuery` when the tab changes
// ✅ Ensure local input updates when switching tabs, but only then
useEffect(() => {
  const newQuery = selectedTab === 'writers' ? writersQuery : booksQuery;
  console.log(`Switching to tab: ${selectedTab}, setting localQuery to: ${newQuery}`);
  setLocalQuery(newQuery);
}, [selectedTab]);


// ✅ Ensure typing updates local state immediately & Redux for the correct tab


  

  const debouncedSetQuery = debounce((value) => {
    dispatch(setQuery({ tab: selectedTab, query: value }));
}, 300);

const handleInputChange = (e) => {
  const newValue = e.target.value;
  setLocalQuery(newValue); // Instant UI update
  dispatch(setQuery({ tab: selectedTab, query: newValue })); // Ensure Redux tracks it

  if (newValue.trim() === "") {
    // 🔥 Reset results and trigger random fetch if input is cleared
    dispatch(setResults({
      ...results,
      [selectedTab]: []
    }));

    dispatch(setRandomFetched({
      ...randomFetched,
      [selectedTab]: false // Force random fetch on next render
    }));
  }
};



  const { userId } = useSelector(state => state.user);
  const userGeoInfo = useSelector(state => state.user.userGeoInfo);
  const userCountryCode = userGeoInfo?.country_code || "US";
  const region = userCountryCode === "GB" ? "UK" : "US";

  useEffect(() => {
  console.log('🧐 Redux State (randomFetched):', randomFetched);
  console.log('🧐 Redux State (Results):', results);
}, [randomFetched, results]);

useEffect(() => {
  if ((!randomFetched.writers || !randomFetched.books) && !isFetchingWriters.current) {
    isFetchingWriters.current = true;
    console.log('📢 Fetching writers and books now...');

    const fetchWriters = !randomFetched.writers
      ? apiInstance.get('/tag-search/random-writers').then(res => {
          console.log("✅ Writers API Response:", res.data);
          return res;
        })
      : Promise.resolve(null);

    const fetchBooks = !randomFetched.books
      ? apiInstance.get("/tag-search/random-books", { params: { consumer_id: userId || undefined, region } })
        .then(res => {
          console.log("✅ Books API Response:", res.data);
          return res;
        })
      : Promise.resolve(null);

    Promise.all([fetchWriters, fetchBooks])
      .then(([writersResponse, booksResponse]) => {
        console.log("🔹 Writers Response:", writersResponse);
        console.log("🔹 Books Response:", booksResponse);

        const updatedResults = { 
          ...results,
          ...(writersResponse?.data?.length ? { writers: writersResponse.data } : {}),
          ...(booksResponse?.data?.results?.length ? { books: booksResponse.data.results } : {})
        };

        const updatedFetched = {
          ...randomFetched,
          ...(writersResponse?.data?.length ? { writers: true } : {}),
          ...(booksResponse?.data?.results?.length ? { books: true } : {})
        };

        console.log("🛠 FINAL updatedResults:", updatedResults);
        console.log("🛠 FINAL updatedFetched:", updatedFetched);

        dispatch(setResults(updatedResults));
        dispatch(setRandomFetched(updatedFetched));
      })
      .catch(error => console.error('❌ Error fetching data:', error))
      .finally(() => {
        isFetchingWriters.current = false;
        console.log("✅ Fetching process complete.");
      });
  }
}, [dispatch, randomFetched, results, searchTimestamp]);

  


  const handleSearch = async () => {
    if (!currentQuery.trim()) {
      dispatch(setResponseMessage({
        ...responseMessage,
        [selectedTab]: 'Please enter a search term.'
      }));
      return;
    }
  
    const searchTime = Date.now();
    setSearchTimestamp(searchTime);
  
    dispatch(setRandomFetched({
      ...randomFetched,
      [selectedTab]: true 
    }));
  
    dispatch(setResults({
      ...results,
      [selectedTab]: []
    }));
  
    dispatch(setLoading(true));
  
    try {
      const endpoint = selectedTab === 'writers' 
        ? '/tag-search/writers' 
        : '/tag-search/books-authors-v3';
  
        const params = {
          query: currentQuery,
          search_type: selectedTab === 'books' ? 'books' : undefined,
          consumer_id: userId || undefined,
          available_at_waterstones: userCountryCode === 'GB' || userCountryCode === 'IE' ? 'true' : null,
          available_at_bn: userCountryCode !== 'GB' && userCountryCode !== 'IE' ? 'true' : null,
          available_at_bam: userCountryCode !== 'GB' && userCountryCode !== 'IE' ? 'true' : null
        };
    
  
      const response = await apiInstance.get(endpoint, { params });
  
      dispatch(setResults({
        ...results,
        [selectedTab]: selectedTab === 'books' ? response.data.results || [] : response.data || []
      }));
  
      dispatch(setResponseMessage({
        ...responseMessage,
        [selectedTab]: response.data.results?.length || response.data.length ? '' : 'No results found'
      }));
    } catch (error) {
      console.error('Search error:', error);
      dispatch(setResponseMessage({
        ...responseMessage,
        [selectedTab]: 'An error occurred while searching. Please try again.'
      }));
    } finally {
      dispatch(setLoading(false));
    }
  };
  

  return (
    <div className="find">
      <Helmet>
        <title>Find | ShorterStories</title>
        <meta name="description" content="Discover writers and books on ShorterStories." />
      </Helmet>
      <Header />

      <div className="find__search-container">
        <div className="find-mode-toggle-container">
          <button
            className={`pill-button-find ${selectedTab === 'writers' ? 'active' : ''}`}
            onClick={() => dispatch(setSelectedTab('writers'))}
          >
            Writers
          </button>
          <button
            className={`pill-button-find ${selectedTab === 'books' ? 'active' : ''}`}
            onClick={() => dispatch(setSelectedTab('books'))}
          >
            Books
          </button>
        </div>

        <div className="find__divider"></div>
        <input
            type="text"
            className="find__search-bar"
            placeholder={`Search for a ${selectedTab === "writers" ? "writer" : "book"}...`}
            value={localQuery}  // ✅ Now it reflects UI state properly
            onChange={handleInputChange}  // ✅ Updates both UI and Redux state
            onKeyPress={(e) => e.key === "Enter" && handleSearch()}
        />


        <button
          className={`find__submit-button ${loading || !currentQuery.trim() ? 'find__submit-button--disabled' : ''}`}
          onClick={handleSearch}
          disabled={loading || !currentQuery.trim()}
        >
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      <div className="find__response">
        {responseMessage[selectedTab] && <p className="find__message">{responseMessage[selectedTab]}</p>}

        {loading ? (
            <p className="find__message"></p>
          ) : !randomFetched[selectedTab] ? (
            <p className="find__message">Loading daily {selectedTab}...</p> // 👈 Show this while fetching random results
          ) : (
            <div className="find__results-list">
              {selectedTab === 'writers' && (results.writers ?? []).map((author, index) => (
                <AuthorSummarySearch key={index} author={author} />
              ))}
              {selectedTab === 'books' && (results.books ?? []).map((book, index) => (
                <BookCardSearch key={index} book={book} />
              ))}
            </div>
          )}
      </div>
    </div>
  );
}

export default Find;
