import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { showGenericModal, hideGenericModal } from '../../store/modalSlice';
import { signOut } from 'aws-amplify/auth';
import { checkAuthenticationStatus, setAuthChecked } from '../../store/userSlice';
import { resetAuthorProfile } from '../../store/authorProfileSlice';
import { clearSelections } from '../../store/taggingSlice';
import { resetPublishingProcess } from '../../store/publishingSlice';
import { resetHomeStats } from '../../store/homeSlice';
import { resetFilters, setShouldApplyFilters, setSelectedFeedType} from '../../store/theFeedSlice';
import {resetLibraryState} from '../../store/librarySlice';




import pancakeImage from '../../assets/images/menufour.jpeg';
import './HeaderHome.css';


function HeaderHome() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [menuVisible, setMenuVisible] = useState(false);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  // Toggles the menu on/off
  const handlePancakeClick = () => {
    setMenuVisible(!menuVisible);
  };


  const handleInfo = (route) => {
    navigate(route);
    setMenuVisible(false);

  };


  const navigateBasedOnPage = () => {
    if (location.pathname.startsWith('/piece/')) {
      navigate('/');
      return;
    }
    switch(location.pathname) {
      case '/writers/profile':
        navigate('/');
        break;
      default:
        navigate('/');
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // If we click anywhere not in .pancake-dropdown or .pancake-buttons
      // -> close the menu
      if (
        menuVisible &&
        !event.target.closest('.pancake-dropdown') &&
        !event.target.closest('.pancake-buttons')
      ) {
        setMenuVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);


  const handleLogout = async () => {
    try {
      setMenuVisible(false);

      await signOut();
      dispatch(setAuthChecked(false));
      dispatch(checkAuthenticationStatus());
      navigate("/");
      dispatch(resetAuthorProfile());
      dispatch(clearSelections());
      dispatch(resetPublishingProcess());
      dispatch(hideGenericModal());
      dispatch(resetHomeStats());
      dispatch(resetFilters());
      dispatch(resetLibraryState());
      dispatch(setShouldApplyFilters(true));
      dispatch(setSelectedFeedType('shorties'));

    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header className="header-main">
      <div className="header-content-second">
        <div className="header-content-left">
          <h1>
            ShorterStories.
          </h1>
        </div>

        <div className="pancake-buttons">
          <button onClick={handlePancakeClick} aria-label="Filter">

              <img src={pancakeImage} alt="Filter" />

          </button>
          {menuVisible && (
            <div className="pancake-dropdown">
              <ul>
                {isAuthenticated ? (
                  <li onClick={handleLogout}>Log Out</li>
                ) : (
                  <li onClick={() => handleInfo("/login")}>
                    Create account / Log in
                  </li>
                )}
                {/* Example: If you still need the filter button in the dropdown, you could add: 
                  <li onClick={handleFilterPress}>Filtering Options</li> 
                */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default HeaderHome;